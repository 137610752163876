import axios, {AxiosProgressEvent} from "axios";
import {Actividad, Archivo} from "../types/servicio";
import {Response} from "../types/response.type";

export async function getActividades(servicioId: string) {
    if (servicioId) {
        const {data} = await axios.get(`/api/servicios/${servicioId}/actividades`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return data;
    }
}

export async function deleteFile(archivo: Archivo):Promise<Response<any>> {
        const {data} = await axios.post(archivo.url + "/eliminar", {}, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return data;
}

export async function uploadFiles(servicioId: string, actividad: Actividad, formData: FormData,trackProgress: (progressEvent: AxiosProgressEvent) => void) :Promise<Response<any>>{
    const {data} = await axios.post(`/api/servicios/${servicioId}/actividades/${actividad.id}/evidencias`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },onUploadProgress:trackProgress,
    });
    return data;
}