import {Actividad, Archivo, Observacion, Servicio} from "../../types/servicio";
import React, {useEffect, useRef, useState} from "react";
import {
    ACTIVIDAD_STATUS_EN_PROGRESO,
    ACTIVIDAD_STATUS_FINALIZADO, FILE_MAX_SIZE,
    formatoFechaTexto, formatoFechaTiempo,
    getUsuarioSesion,
    isAdmin,
    isCliente,
    isCoordinador,
    isOperativo,
    isVentas,
    OK
} from "../../utils";
import axios from "axios";
import {
    cancelarActividad,
    cerrarActividad, eliminarArchivo,
    eliminarEvidencias,
    guardarEvidencias, guardarObservaciones, rechazarActividad
} from "../../services/conem-services";
import {Button, Col, Container, Dropdown, DropdownButton, Form, Row, Spinner} from "react-bootstrap";
import Advertencia from "../mensajes/Advertencia";
import ArchivoItem from "../documento/ArchivoItem";
import ConfirmacionModal from "../common/ConfirmacionModal";
import {eliminarObservaciones} from "../../services/observaciones.services";
import RoundedDashedBox from "../rounded-dashed-box/RoundedDashedBox";
import FileSelector from "../FileSelector/FileSelector";
import {deleteFile, uploadFiles} from "../../services/actividad.services";
import {AxiosProgressEvent} from "axios/index";


export default function ActividadList(props: {
    servicioId: string,
    folioSecretariaEstatal: string | null,
    actividades: Actividad[],
    update?: (actividades: Actividad[]) => void,
}) {

    const [actividades, setActividades] = useState<Actividad[]>([]);

    useEffect(() => {
        setActividades(props.actividades);
    }, [props.actividades]);

    return (<div className="stepper-container">
            {
                actividades?.map((item: Actividad, idx: number) => {
                    if (item.clave === 'revision.calidad' && isCliente()) {
                        return <></>
                    } else {
                        return (
                            <div
                                className={`step ${item.status === 'en_progreso' ? 'active' : (item.status === 'finalizado' ? 'completed' : '')}`}>
                                <div className="v-stepper">
                                    <div className="stepper-circle"></div>
                                    <div className="stepper-line"></div>
                                </div>
                                <div
                                    className={idx < (actividades.length - 1) ? 'stepper-content' : 'stepper-content'}>
                                    <ActividadItem actividad={item}
                                                   servicioId={props.servicioId}
                                                   folioSecretariaEstatal={props.folioSecretariaEstatal}
                                                   update={(actividades: Actividad[]) => {
                                                       setActividades(actividades);
                                                       if (props.update)
                                                           props.update(actividades);
                                                   }}
                                    />
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>

    );
}


function ActividadItem(
    props: {
        servicioId: string,
        folioSecretariaEstatal: string | null,
        actividad: Actividad,
        showErrorMessage?: (message: string) => void,
        update: (actividades: Actividad[]) => void
    }
) {

    const [confirmarEliminarArchivo, setConfirmarEliminarArchivo] = useState<boolean>(false)
    const [archivoEliminar, setArchivoEliminar] = useState<Archivo | null>(null);

    const [archivos, setArchivos] = useState<Archivo[]>([]);
    const [confirmarFinalizarActividad, setConfirmarFinalizarActividad] = useState<boolean>(false);
    const [confirmarCancelarActividad, setConfirmarCancelarActividad] = useState<boolean>(false);
    const [confirmarRechazarActividad, setConfirmarRechazarActividad] = useState<boolean>(false);

    const [observacionesAutoridad, setObservacionesAutoridad] = useState<boolean>(false);
    const [folioSecretariaEstatal, setFolioSecretariaEstatal] = useState<string>('');
    const [percentCompleted, setPercentCompleted] = useState<number>(0);


    useEffect(() => {
        setArchivos(props.actividad.evidencias.archivos);
        if (props.actividad.observacionesAutoridad)
            setObservacionesAutoridad(props.actividad.observacionesAutoridad)
        if (props.folioSecretariaEstatal)
            setFolioSecretariaEstatal(props.folioSecretariaEstatal)
    }, [props.actividad, props.folioSecretariaEstatal]);

    const enableActivityOptions = (actividad: Actividad) => {
        if (actividad && actividad.status === 'en_progreso') {
            if (isAdmin() || isVentas()) { //&& actividad.rolesPropietarios.includes("ROLE_ADMIN")) {
                return true;
            }
            if (!isCliente()) {
                return true;
            }
        }
        return false;
    }

    const isEnProgreso = () => {
        return props.actividad.status === 'en_progreso';
    }

    const showErrorMessage = (message: string) => {
        if (props.showErrorMessage) {
            props.showErrorMessage(message);
        }
    }

    return (
        <div className={isEnProgreso() ? 'rounded' : ''} style={{padding: '10px'}}>
            <Row>
                <Col style={{borderRight: '1px solid #fafafa'}} sm='7'>
                    <div style={{marginBottom: '10px', paddingRight: '10px'}}>
                        <div style={{marginBottom: '10px'}}>
                            <label className={`fw-bold  fs-6 conem-text-actividad-activa`}>
                                {props.actividad.descripcion}</label>
                        </div>
                        {
                            (getUsuarioSesion() !== null && enableActivityOptions(props.actividad)) &&
                            <div style={{marginLeft: '20px'}}>
                                {
                                    props.actividad.clave === 'validacion.informacion' &&
                                    <>
                                        <Button size='sm' variant='outline-dark'
                                                onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Información validada</Button>

                                        <Button size='sm' variant='outline-dark'
                                                disabled={!isCoordinador() && !isAdmin()}
                                                onClick={() => {
                                                    setConfirmarCancelarActividad(true);
                                                }}>Cancelar actividad</Button>
                                    </>
                                }
                                {
                                    (props.actividad.clave !== 'revision.calidad' && props.actividad.clave !== 'validacion.informacion') &&
                                    <>
                                        <Button size='sm' variant='outline-dark' disabled={archivos.length === 0}
                                                onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Finalizar actividad</Button>
                                        {
                                            (props.actividad.clave !== 'reporte.inspeccion') &&
                                            <Button size='sm' variant='outline-dark'
                                                    disabled={!isCoordinador() && !isAdmin()}
                                                    onClick={() => {
                                                        setConfirmarCancelarActividad(true);
                                                    }}>Cancelar actividad</Button>
                                        }
                                        {
                                            archivos.length === 0 && <div style={{fontSize: '0.7em', color: '#939292'}}>
                                                <span><i className='bi bi-exclamation-circle-fill'></i> Para finalizar una actividad debe agregar al menos un archivo de evidencia</span>
                                            </div>
                                        }

                                    </>
                                }

                                {
                                    props.actividad.clave === 'revision.calidad' &&
                                    <>
                                        {
                                            archivos.length === 0 && <div className='file-size-label'>
                                                <label>Para finalizar una actividad debe agregar al menos un archivo de
                                                    evidencia</label>
                                            </div>
                                        }
                                        <div className='d-inline-flex'>
                                            <DropdownButton
                                                disabled={isVentas() || isOperativo() || archivos.length === 0}
                                                size="sm" title="Actualizar">
                                                <Dropdown.Item eventKey="1" onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Finalizar actividad</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" onClick={() => {
                                                    setConfirmarRechazarActividad(true);
                                                }}>Rechazar actividad</Dropdown.Item>
                                            </DropdownButton>
                                            <Button size='sm' variant='outline-dark' style={{marginLeft:'20px'}}
                                                    disabled={!isCoordinador() && !isAdmin()}
                                                    onClick={() => {
                                                        setConfirmarCancelarActividad(true);
                                                    }}>Cancelar actividad</Button>
                                        </div>
                                    </>

                                }
                            </div>
                        }
                    </div>

                    <div>
                        {
                            (props.actividad.status === 'en_progreso' && props.actividad.clave === 'liberacion.anuencia') &&
                            <div style={{marginLeft: '20px'}}>
                                <Advertencia showIcon={false}
                                             mensaje={'Esta actividad está asignada a la autoridad correspondiente por lo que tomará un\n' +
                                                 '                                    tiempo ' +
                                                 '                                    variable para su atención.'}/>
                            </div>
                        }

                        <div style={{marginLeft: '20px', marginBottom: '10px'}}>
                            <label style={{fontSize: '0.9em'}}>
                                Fecha estimada de finalización: <span
                                className='fw-bold'>{(props.actividad.clave !== 'liberacion.anuencia' && props.actividad.fechaEstimada) ? formatoFechaTexto(props.actividad.fechaEstimada) : '------'}</span>
                            </label>
                        </div>

                        {
                            props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO &&
                            <div style={{marginBottom: '10px', marginLeft: '20px'}}>
                                <label style={{fontSize: '0.9em'}}>
                                    Fecha real de finalización: <span
                                    className='fw-bold'>{props.actividad.status === 'finalizado' ? formatoFechaTexto(props.actividad?.fechaFinalizado) : '------'}</span>
                                </label>
                            </div>
                        }
                    </div>


                    <div style={{marginLeft: '20px'}}>
                        {
                            (props.actividad.clave === 'ingreso.pc' && (props.actividad.status === 'finalizado' || props.actividad.status === 'en_progreso')) && <>
                                <Form.Group className="col-md"
                                            style={{marginBottom: "20px"}}>
                                    <Form.Label style={{fontSize: '0.9em'}}>Folio de la secretaría estatal</Form.Label>
                                    <Form.Control type="text"
                                                  readOnly={props.actividad.status !== 'en_progreso'}
                                                  className="formFont"
                                                  maxLength={30}
                                                  style={{fontSize: '0.9em'}}
                                                  isInvalid={false}
                                                  value={folioSecretariaEstatal}
                                                  onChange={(e) => setFolioSecretariaEstatal(e.target.value)}
                                                  onBlur={() => {

                                                  }}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{''}</Form.Control.Feedback>
                                </Form.Group>
                                <div className="mb-3">
                                    <Form.Check style={{fontSize: '0.9em'}}
                                                checked={observacionesAutoridad}
                                                disabled={props.actividad.status !== 'en_progreso'}
                                                onChange={(e) => {
                                                    setObservacionesAutoridad(e.target.checked);
                                                }} inline label='Hubo observaciones de la autoridad'/>
                                </div>
                            </>
                        }

                        <ObservacionItem eliminable={props.actividad.status === 'en_progreso'}
                                         actividad={props.actividad}
                                         servicioId={props.servicioId}
                                         observaciones={props.actividad.observaciones ? props.actividad.observaciones : []}
                                         update={props.update}/>
                    </div>

                </Col>


                <Col style={{marginLeft: '10px'}}>
                    {
                        (
                            (props.actividad.clave !== 'validacion.informacion' && !(isCliente() && props.actividad.clave !== 'revision.calidad')) &&
                            (props.actividad.status === 'en_progreso' || props.actividad.status === 'finalizado')
                        )
                        && <>
                        <Row>
                            <Col>
                                <label
                                    className={`fw-bold fs-6`}>
                                    Evidencias
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    <FileSelector readOnly={ props.actividad.status === 'finalizado' }
                                        onSelect={() => {
                                        }}
                                        text={"Agregar evidencias"}
                                        multiple={true}
                                        archivos={archivos}
                                        percentCompleted={percentCompleted}
                                        upload={async (files: any) => {
                                            const formData = new FormData();
                                            for (let i = 0; i < files.length; i++) {
                                                formData.append("files", files[i]);
                                            }
                                            const resp = await uploadFiles(props.servicioId,
                                                props.actividad, formData, (progressEvent: AxiosProgressEvent) => {
                                                    // @ts-ignore
                                                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                                    setPercentCompleted(percentCompleted);
                                                })
                                            if (resp.statusCode === OK) {
                                                return resp.data;
                                            }
                                            return [];
                                        }}
                                        delete={async (archivo: Archivo) => {
                                            try {
                                                const resp = await deleteFile(archivo)
                                                return resp.statusCode === OK
                                            } catch (error) {
                                                return false;
                                            }
                                        }}
                                    />


                                }

                                {
                                    /*
                                    (archivos.length === 0 && props.actividad.status === 'en_progreso') && <RoundedDashedBox label='Sin archivos' sm={true}/>
                                    */

                                }


                                {
                                    /*
                                    archivos.map((archivo: Archivo) => {
                                        return <ArchivoItem archivo={archivo}
                                                            servicioId={''}
                                                            documentoId={''}
                                                            statusServicio={'finalizado'}
                                                            seleccionable={props.actividad.status !== 'finalizado'}
                                                            eliminable={getUsuarioSesion() !== null && props.actividad.status !== 'finalizado'}
                                                            onDelete={(item: Archivo) => {
                                                                setArchivoEliminar(item);
                                                                setConfirmarEliminarArchivo(true);
                                                            }}
                                                            onSelect={(checked: boolean) => {
                                                                changeSelection(archivo, checked);
                                                            }}></ArchivoItem>
                                    })*/
                                }
                                {
                                    /*
                                    localFilesSelected &&
                                    [...localFilesSelected]?.map((file: any) =>
                                        <ArchivoItem archivo={file}
                                                     servicioId={''}
                                                     documentoId={''}
                                                     statusServicio={'finalizado'}
                                                     seleccionable={false} eliminable={false}
                                                     onSelect={() => {
                                                     }}/>
                                    )*/
                                }
                            </Col>

                        </Row>

                    </>
                    }
                </Col>

            </Row>


            <ConfirmacionModal show={confirmarFinalizarActividad} onCancel={() => setConfirmarFinalizarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                cerrarActividad(props.servicioId, props.actividad, observacionesAutoridad, folioSecretariaEstatal).then(data => {
                    props.update(data);
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar cerrar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarFinalizarActividad(false);
                })

            }} message={
                [`¿Está seguro de finalizar la actividad ${props.actividad.descripcion}?`]}
            />


            <ConfirmacionModal show={confirmarCancelarActividad} onCancel={() => setConfirmarCancelarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                cancelarActividad(props.servicioId, props.actividad).then(data => {
                    props.update(data)
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar cancelar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarCancelarActividad(false);
                })

            }} message={
                [`¿Está seguro de cancelar la actividad ${props.actividad.descripcion}?`]}
            />

            <ConfirmacionModal show={confirmarRechazarActividad} onCancel={() => setConfirmarRechazarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                rechazarActividad(props.servicioId, props.actividad).then(data => {
                    props.update(data)
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar rechazar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarRechazarActividad(false);
                })

            }} message={
                [`¿Está seguro de rechazar la actividad ${props.actividad.descripcion}?`]}
            />
            {
                archivoEliminar &&
                <ConfirmacionModal show={confirmarEliminarArchivo}
                                   onCancel={() => setConfirmarEliminarArchivo(false)}
                                   title={'Confirmación'} onAccept={() => {

                    setConfirmarEliminarArchivo(false);
                    eliminarArchivo(archivoEliminar).then(data => {
                        props.update(data)
                    }).catch(err => {
                        showErrorMessage('Lo sentimos ocurrió un error al intentar eliminar el archivo, por favor inténtelo más tarde');
                    }).finally(() => {
                        setArchivoEliminar(null);
                    })
                }} message={[`Esta seguro de eliminar el archivo de evidencias ${archivoEliminar.nombre}`]}/>


            }
        </div>
    )
}

function ObservacionItem(
    props: {
        eliminable: boolean,
        observaciones: Observacion[],
        actividad: Actividad,
        servicioId: string,
        update: (actividades: Actividad[]) => void
    }
) {

    const [editando, setEditando] = useState<boolean>(false);
    const [actualizando, setActualizando] = useState<boolean>(false);
    const [nuevaObservacion, setNuevaObservacion] = useState<string>('');

    useEffect(() => {
        setEditando(false)
        setActualizando(false);
    }, []);

    const submit = () => {
        if (nuevaObservacion.trim()) {
            setActualizando(true);
            guardarObservaciones(props.servicioId, props.actividad, nuevaObservacion).then(data => {
                props.update(data);
            }).catch(err => {

            }).finally(() => {
                setNuevaObservacion('')
                setActualizando(false);
            });
        }
    }

    return (
        <>
            <div>
                {
                    props.observaciones.length > 0 ? <>
                            {
                                props.observaciones.map((item) => {
                                    return (
                                        <div className='rounded' style={{
                                            border: '1px solid #d9dbde',
                                            background: 'rgba(254,242,242,0.78)',
                                            marginBottom: '4px'
                                        }}>
                                            <Row>
                                                <Col>
                                                    <div style={{padding: '10px'}}>
                                                        <p style={{
                                                            fontSize: '0.9em',
                                                            color: '#3c3c3c'
                                                        }}>{item.descripcion}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Container>
                                                        <Row>
                                                            <Col sm='10'>
                                                                <div>
                                                                    <label style={{
                                                                        fontSize: '0.7em',
                                                                        color: '#939292'
                                                                    }}>{formatoFechaTiempo(item.fecha)}</label>
                                                                </div>
                                                                <div>
                                                                    <label style={{
                                                                        fontSize: '0.7em',
                                                                        color: '#939292'
                                                                    }}>{item.usuario.correo}</label>
                                                                </div>

                                                            </Col>
                                                            {
                                                                props.eliminable && <Col>
                                                                    <a className='file-size-label' onClick={() => {
                                                                        if (props.actividad.id) {
                                                                            eliminarObservaciones(props.servicioId, props.actividad.id, item.id).then(
                                                                                data => {
                                                                                    props.update(data);
                                                                                }
                                                                            ).catch(err => console.log(err))
                                                                        }

                                                                    }}>Eliminar</a>
                                                                </Col>
                                                            }

                                                        </Row>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                            {
                                (!isCliente() && getUsuarioSesion()) !== null &&
                                <>
                                    <Button variant="outline-dark" size='sm' className='link-accion'
                                            disabled={actualizando} style={{marginTop: '5px'}}
                                            hidden={(props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO || editando)}
                                            onClick={() => {
                                                setEditando(true);
                                            }}>
                                        Agregar observaciones
                                    </Button>
                                </>
                            }
                        </> :
                        <>
                            {
                                (!isCliente() && getUsuarioSesion() !== null && props.actividad.status === ACTIVIDAD_STATUS_EN_PROGRESO) &&
                                <Button variant="outline-dark" size='sm' className='link-accion'
                                        disabled={actualizando} onClick={() => {
                                    setEditando(true);
                                }}>
                                    Agregar observaciones
                                </Button>
                            }
                        </>
                }
            </div>
            {
                (editando) &&
                <div>
                    <Form.Group className="mb-3" controlId="observaciones"
                                hidden={props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO}>
                        <Form.Label><label style={{fontSize: '0.9em'}}>Observaciones:</label></Form.Label>
                        <Form.Control as="textarea" style={{fontSize: '0.9em'}} rows={3} value={nuevaObservacion}
                                      onChange={(e) => setNuevaObservacion(e.target.value)}/>
                    </Form.Group>
                    <Button variant="outline-danger" style={{width: '150px'}} size='sm' className='link-accion'
                            hidden={props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO}
                            disabled={actualizando} onClick={() => {
                        setEditando(false);
                    }}>
                        Cerrar
                    </Button>
                    <Button variant="outline-dark" size='sm' className='link-accion'
                            disabled={actualizando} onClick={submit}
                            hidden={props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO}>
                        {
                            !actualizando ? <>Guardar observaciones</> : <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Guardando observaciones</span>
                            </>
                        }
                    </Button>
                </div>
            }
        </>

    )
}