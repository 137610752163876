import {byteConverter, FILE_MAX_SIZE, formatoFechaTiempo} from "../../utils";
import React, {useEffect, useRef, useState} from "react";
import cloneDeep from "lodash.clonedeep";
import './FileSelector.css';
import {Archivo} from "../../types/servicio";

export default function FileSelector(
    props: {
        readOnly?:boolean,
        multiple?: boolean,
        text?: string,
        onSelect: (file: any) => void,
        archivos?: Archivo[],
        upload?: (files: any) => Promise<Archivo[]>,
        delete?:(archivo: Archivo) => Promise<boolean>,
        percentCompleted?: number,
    }
) {

    const [files, setFiles] = useState<any>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [archivos, setArchivos] = useState<Archivo[]>([]);


    useEffect(() => {
        if (props.archivos){
            setArchivos(props.archivos)
        }
        //setFiles([]);
    }, [props.archivos]);


    useEffect(() => {
        // @ts-ignore
        if (hiddenFileInputRef.current.value) {
            // @ts-ignore
            hiddenFileInputRef.current.value = "";
        }
        setFiles([]);
    }, [])

    const clickSelectFiles = () => {
        if (hiddenFileInputRef && hiddenFileInputRef.current) {
            // @ts-ignore
            hiddenFileInputRef.current.click();
        }
    }

    const hiddenFileInputRef = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let newFiles = []
        const selectefFiles = [];

        if (props.multiple) {
            newFiles = cloneDeep(files);
        } else {
            setFiles([])
        }
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                const newFile = e.target.files.item(i);
                if (newFile) {
                    if (newFile.size <= 0 || newFile.size >= FILE_MAX_SIZE) {
                        setFiles([]);
                        // @ts-ignore
                        e.target.value = null
                        props.onSelect(null);
                        //mostrarMensajeError("El tamaño mínimo de archivo es 1 byte y menor a 100 MB");
                        return;
                    } else {
                        if (props.multiple) {
                            const refFile = new File([newFile], newFile.name, {
                                type: newFile.type,
                                lastModified: newFile.lastModified,
                            });
                            selectefFiles.push(refFile);
                            newFiles.push(refFile);
                        } else {
                            props.onSelect(newFile);
                            //newFiles.push(newFile);
                            //setFiles(newFiles);
                        }
                    }
                }
            }

            if (props.upload) {
                setFiles(newFiles);
                setIsUploading(true);
                    props.upload(selectefFiles).then(arc => {
                        const newArchivos=[...archivos,...arc];
                        setArchivos(newArchivos);
                        setFiles([]);
                }).finally(() => {
                    setIsUploading(false);
                })
            }
        }
        // @ts-ignore
        e.target.value = null
    }

    function getName(file: any) {
        if (file.nombre) {
            return file.nombre;
        }
        return file.name;
    }

    function getSize(file: any) {
        if (file.tamanio) {
            return file.tamanio;
        }
        return file.size;
    }

    const isInstanceOfArchivo = (file: any) => !!file.tamanio;

    function FileRow(file: any, index: number) {

        return (
            <li className="list-group-item">
                <div className='container rounded file-selector-item'>
                    <div className='row'>
                        <div className='col-sm'>
                            <div className='d-inline-flex'>
                                <div style={{marginRight: '10px'}}>
                                    <i className='fs-3 bi bi-file-earmark'/>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center file-selector-item-name'>{getName(file)}</div>
                                    <div className='file-selector-item-details'>Tamaño: {byteConverter(getSize(file), 2)}</div>
                                    {
                                        file.fechaSubido &&
                                        <div
                                            className='file-selector-item-details'>Subido: {formatoFechaTiempo(file.fechaSubido)}</div>
                                    }
                                    {
                                        file.subidoPor &&
                                        <div
                                            className='file-selector-item-details'>Subido por: {file.subidoPor}</div>
                                    }
                                </div>
                            </div>
                            {
                                //isUploading es activado cuando se pasa la funcion para subir archivos
                                (isUploading && !isInstanceOfArchivo(file) && props.percentCompleted !== undefined) &&
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated"
                                         role="progressbar" aria-valuenow={100}
                                         aria-valuemin={0} aria-valuemax={100}
                                         style={{width: `100%`}}/>
                                </div>
                            }
                        </div>
                        {
                            (!isUploading && !props.readOnly)&& <div
                                className='rounded col-sm-1 d-flex align-items-center justify-content-center'>
                                <a onClick={() => {
                                    if (isInstanceOfArchivo(file) && props.delete) {
                                        props.delete(file).then(resp=>{
                                            if(resp){
                                                let newFiles = cloneDeep(archivos);
                                                newFiles.splice(index, 1);
                                                setArchivos(newFiles);
                                            }
                                        });

                                    }else {
                                        let newFiles = cloneDeep(files);
                                        newFiles.splice(index, 1);
                                        setFiles(newFiles);
                                    }
                                }}>Eliminar</a>
                            </div>
                        }

                    </div>
                </div>
            </li>
        )
    }

    return (<>
            <input id="file" type="file" multiple={props.multiple} ref={hiddenFileInputRef} style={{display: 'none'}}
                   onChange={handleFileChange}/>

            <div className="card"
                 style={{borderStyle: 'dashed', borderWidth: '1px', borderColor: '#747373'}}>
                {
                    ((props.multiple || (archivos!==undefined && archivos.length === 0)) && (props.readOnly===false || props.readOnly===undefined)) &&
                    <div className="card-header">
                        <div
                            className='align-content-center align-items-center justify-content-center rounded'
                            style={{color: '#565555'}}>
                            <a onClick={clickSelectFiles}>
                                <i className='fs-5 bi bi-plus-square-fill'></i>
                                <span
                                    style={{marginLeft: '5px'}}>{props.text ? props.text : 'Agregar documento'}</span>
                            </a>
                        </div>
                    </div>
                }
                <ul className="list-group list-group-flush">
                    {
                        archivos?.map((archivo: Archivo, index: number) => {
                            return FileRow(archivo, index)
                        })
                    }
                    {
                        files.map((item: any, index: any) => {
                            return FileRow(item, index)
                        })
                    }
                </ul>
            </div>

        </>
    )
}