import axios from "axios";


export async function eliminarObservaciones(servicioId: string, actividadId: string, observacionId: string) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/actividades/${actividadId}/observacion/${observacionId}/delete`, {},{
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return data;
}