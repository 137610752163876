import {Archivo, Documento, Servicio} from "../../types/servicio";
import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import ArchivoItem from "./ArchivoItem";
import {
    eliminarArchivo,
    eliminarArchivos,
    subirArchivo,
    updateCreditoAutorizado, updatePagoAnticipo, updatePagoLiquidacion,
    verificarDocumento
} from "../../services/conem-services";
import {FILE_MAX_SIZE, getUsuarioSesion, isAdmin, isCalidad, isOperativo, isVentas, OK} from "../../utils";
import ConfirmacionModal from "../common/ConfirmacionModal";
import cloneDeep from "lodash.clonedeep";
import FileSelector from "../FileSelector/FileSelector";
import {deleteFile, uploadFiles} from "../../services/documento.services";
import {AxiosProgressEvent} from "axios/index";


export default function DocumentoItem(
    props: {
        documento: Documento,
        servicio: Servicio,
        showErrorMessage?: (message: string) => void,
        onUpdate?: (documento: Documento) => void,
        updateCreditoAutorizado?:(creditoAutorizado:boolean)=>void,
        updatePagoAnticipo?:(pagoAnticipo:boolean)=>void,
        updatePagoLiquidacion?:(pagoLiquidacion:boolean)=>void,
    }) {

    const [creditoAutorizado,setCreditoAutorizado]=useState(props.servicio.creditoAutorizado)
    const [pagoAnticipo,setPagoAnticipo]=useState(props.servicio.pagoAnticipo)
    const [pagoLiquidacion,setPagoLiquidacion]=useState(props.servicio.pagoLiquidacion)


    const [confirmarEliminarArchivo, setConfirmarEliminarArchivo] = useState<boolean>(false)
    const [archivoEliminar, setArchivoEliminar] = useState<Archivo | null>(null);
    const hiddenFileInput = useRef(null);
    const [localFilesSelected, setLocalFilesSelected] = useState<any>(null);
    const [archivos, setArchivos] = useState<Archivo[]>([]);
    const [archivosSeleccionados, setArchivosSeleccionados] = useState<Archivo[]>([]);

    const [eliminandoArchivos, setEliminandoArchivos] = useState<boolean>(false);

    useEffect(() => {
        setArchivos(props.documento.archivos);
        setLocalFilesSelected(null);
        setArchivosSeleccionados([]);
        setEliminandoArchivos(false);
    }, [props.documento, props.servicio]);


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let valid = true;
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files.item(i);
                if (file && (file.size <= 0 || file.size >= FILE_MAX_SIZE)) {
                    valid = false;
                    showErrorMessage("El tamaño mínimo de archivo es 1 byte y menor a 100 MB");
                    return;
                }
            }
            if (valid && props.documento.id) {
                const formData = new FormData();
                setLocalFilesSelected(e.target.files);
                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append("files", e.target.files[i]);
                }
                subirArchivo(props.servicio, props.documento.id, formData).then(
                    resp => {
                        if (resp.statusCode === OK) {
                            if (resp.data.length > 0) {
                                const newArchivos = [...archivos, ...resp.data];

                                setArchivos(newArchivos);
                                if (props.onUpdate) {
                                    const newDocumento = {...props.documento, archivos: newArchivos};
                                    props.onUpdate(newDocumento);
                                }

                            }
                        } else {
                            showErrorMessage(resp.message);
                        }
                    }
                ).catch(err => {
                    showErrorMessage("Lo sentimos ocurrió un error al intentar subir los archivos, por favor inténtelo más tarde");
                }).finally(() => {
                    setLocalFilesSelected(null)
                });

            }
        }
    };

    const showErrorMessage = (message: string) => {
        if (props.showErrorMessage) {
            props.showErrorMessage(message);
        }
    }


    const [percentCompleted, setPercentCompleted] = useState<number>(0);

    return (
        <div style={{paddingBottom: '10px'}} hidden={props.documento.clave === 'comprobante.pago' && isOperativo()}>
            <input id="file" type="file" multiple={true} ref={hiddenFileInput} style={{display: 'none'}} onChange={handleFileChange}/>
            <Row>
                <Col sm='6'>
                    <div className='d-inline-flex'>
                        <img src="/folder-icon.svg" width='28' height='28'/>
                        <div style={{marginLeft: '10px'}}>
                            <label className='fw-bold fs-6'>{props.documento.nombre}</label>
                        </div>
                    </div>
                    {

                        ((isAdmin() || isVentas() || isCalidad()) && props.documento.clave === 'comprobante.pago') &&
                        <div className='d-inline-flex' style={{marginLeft: '40px',marginBottom:"10px"}}>
                            <div className="form-check" style={{marginLeft: '15px', marginRight: '15px'}}>
                                <label className="form-check-label" style={{fontSize: '0.9em', color: '#363535'}}
                                       htmlFor="requiereFactura">
                                    Crédito autorizado
                                </label>
                                <input className="form-check-input" type="checkbox"
                                       id='requiereFactura'
                                       checked={creditoAutorizado}
                                       onChange={(e) => {
                                           if (props.servicio.id != null) {
                                               setCreditoAutorizado(e.target.checked);
                                               updateCreditoAutorizado(props.servicio.id, e.target.checked).then(resp => {
                                                   if (resp.statusCode === OK && props.updateCreditoAutorizado) {
                                                       props.updateCreditoAutorizado(e.target.checked);
                                                   }
                                               }).catch(err=>{console.log(err)});
                                           }
                                       }}/>
                            </div>
                            <div className="form-check" style={{marginLeft: '15px', marginRight: '15px'}}>
                                <label className="form-check-label" style={{fontSize: '0.9em', color: '#363535'}}
                                       htmlFor="requiereFactura">
                                    Pago de anticipo
                                </label>
                                <input className="form-check-input" type="checkbox"
                                       id='requiereFactura'
                                       checked={pagoAnticipo}
                                       onChange={(e) => {
                                           if (props.servicio.id != null) {
                                               setPagoAnticipo(e.target.checked);
                                               updatePagoAnticipo(props.servicio.id, e.target.checked).then(resp => {
                                                   if (resp.statusCode === OK && props.updatePagoAnticipo) {
                                                       props.updatePagoAnticipo(e.target.checked);
                                                   }
                                               });
                                           }
                                       }}/>
                            </div>
                            <div className="form-check" style={{marginLeft: '15px', marginRight: '15px'}}>
                                <label className="form-check-label" style={{fontSize: '0.9em', color: '#363535'}}
                                       htmlFor="requiereFactura">
                                    Pago de Liquidación
                                </label>
                                <input className="form-check-input" type="checkbox"
                                       id='requiereFactura'
                                       checked={pagoLiquidacion}
                                       onChange={(e) => {
                                           if (props.servicio.id != null) {
                                               setPagoLiquidacion(e.target.checked);
                                               updatePagoLiquidacion(props.servicio.id, e.target.checked).then(resp => {
                                                   if (resp.statusCode === OK && props.updatePagoLiquidacion) {
                                                       props.updatePagoLiquidacion(e.target.checked);
                                                   }
                                               });
                                           }
                                       }}/>
                            </div>
                        </div>

                    }

                </Col>
                <Col>
                    <div className='d-flex justify-content-end'>


                        {
                            /*
                            getUsuarioSesion() !== null && <>

                                <Button variant="outline-dark" size='sm' className='link-accion'
                                        disabled={localFilesSelected !== null} onClick={clickSelectFiles}>
                                    {
                                        localFilesSelected === null ? <>Subir archivos</> : <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span>Subiendo archivos...</span>
                                        </>
                                    }
                                </Button>
                            </>

                             */
                        }
                        {
                            archivosSeleccionados.length > 0 &&
                            <Button variant="outline-dark" size='sm' className='link-accion' onClick={() => {
                                setEliminandoArchivos(true);
                                eliminarArchivos(props.servicio, props.documento, archivosSeleccionados).then(
                                    resp => {
                                        if (resp.statusCode === OK) {
                                            setArchivos(resp.data);
                                            setArchivosSeleccionados([]);
                                            if (props.onUpdate) {
                                                const newDocumento = {...props.documento, archivos: resp.data};
                                                props.onUpdate(newDocumento);
                                            }
                                        } else {
                                            showErrorMessage(resp.message);
                                        }
                                    }
                                ).catch(err => {
                                    showErrorMessage('Lo sentimos ocurrió un error al intentar eliminar los archivos, por favor inténtelo más tarde');
                                }).finally(() => {
                                    setEliminandoArchivos(false)
                                });
                            }}>
                                {
                                    eliminandoArchivos ? <><Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /><span>Eliminando archivos...</span></> : <>Eliminar archivos</>

                                }
                            </Button>
                        }
                    </div>
                </Col>
            </Row>
            <div style={{marginLeft: '60px'}}>



                <FileSelector
                    onSelect={()=>{}}
                    text="Seleccionar archivos"
                    multiple={true}
                    archivos={archivos}
                    percentCompleted={percentCompleted}
                    upload={async (files:any)=>{
                        if (props.documento.id) {
                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append("files",files[i]);
                            }
                            const resp=await uploadFiles(props.servicio.id, props.documento.id, formData, (progressEvent: AxiosProgressEvent) => {
                                // @ts-ignore
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setPercentCompleted(percentCompleted);
                            })
                            if (resp.statusCode === OK) {
                                return resp.data;
                            }
                        }
                        return [];
                    }}
                    delete={async (archivo:Archivo)=>{
                        const resp=await deleteFile(archivo)
                        return resp.statusCode===OK
                    }}
                />



                <Row>
                    {
                        /*
                        archivos.map((archivo: Archivo) => {
                            return <ArchivoItem archivo={archivo}
                                                servicioId={''}
                                                documentoId={''}
                                                statusServicio={'finalizado'}
                                                seleccionable={getUsuarioSesion() !== null}
                                                eliminable={getUsuarioSesion() !== null}
                                                onDelete={(item: Archivo) => {
                                                    setArchivoEliminar(item);
                                                    setConfirmarEliminarArchivo(true);
                                                }}
                                                onSelect={(checked: boolean) => {
                                                    changeSelection(archivo, checked);
                                                }}/>
                        })*/
                    }
                    {
                        localFilesSelected &&
                        [...localFilesSelected]?.map((file: any) =>
                            <ArchivoItem archivo={file} servicioId={''} documentoId={''}
                                         statusServicio={'finalizado'}
                                         seleccionable={true} eliminable={false}
                                         onSelect={() => {
                                         }}/>)
                    }
                </Row>
            </div>
            <hr/>
            {
                archivoEliminar &&
                <ConfirmacionModal show={confirmarEliminarArchivo}
                                   onCancel={() => setConfirmarEliminarArchivo(false)}
                                   title={'Confirmación'} onAccept={() => {

                    setConfirmarEliminarArchivo(false);
                    eliminarArchivo(archivoEliminar).then(resp => {
                        if (resp.statusCode === OK) {
                            setArchivos(resp.data)
                            if (props.onUpdate) {
                                const newDocumento = {...props.documento, archivos: resp.data};
                                props.onUpdate(newDocumento);
                            }
                        } else {
                            showErrorMessage(resp.message);
                        }
                    }).catch(err => {
                        showErrorMessage('Lo sentimos ocurrió un error al intentar eliminar el archivo, por favor inténtelo más tarde');
                    }).finally(() => {
                        setArchivoEliminar(null);
                    })
                }} message={[`¿Está seguro de eliminar el archivo ${archivoEliminar.nombre}?`]}/>
            }
        </div>
    )

}