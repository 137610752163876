import {Alert, Button, Col, Container, Form, ListGroup, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {omit} from "lodash";
import {Archivo, CatalogoServicio, Documento, Subservicio, Tarea} from "../../types/servicio";
import {EditText} from "react-edit-text";
import TareaItem from "./TareaItem";
import {
    FILE_MAX_SIZE, formatoFechaSimple,
    formatoMoneda,
    getSumaSubtotalSubservicios,
    getUsuarioSesionUsername,
    OK,
    truncarTexto
} from "../../utils";
import DeleteButton from "../common/DeleteButton";
import cloneDeep from "lodash.clonedeep";
import ArchivoItem from "../documento/ArchivoItem";
import React, {useEffect, useRef, useState} from "react";
import {actualizarCatalogoServicio, eliminarArchivo, uploadTmpFile} from "../../services/conem-services";
import ConfirmacionModal from "../common/ConfirmacionModal";
import {useFormInput} from "../cotizacion/useFormInput";
import axios from "axios";
import RoundedDashedBox from "../rounded-dashed-box/RoundedDashedBox";

async function actualizarPlantillaCotizacion(id: string, formData: FormData) {
    const {data} = await axios.post(`/api/catalogo/${id}/plantilla`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return data;
}

const fetchTemplateReference=async (id:string)=>{
    const {data} = await axios.get(`/api/catalogo/${id}/plantilla`);
    return data;
}

export default function FormCatalogoServicio(props: {
    enableTestFile: boolean,
    catalogoServicio: CatalogoServicio,
    ocultar: () => void,
    editarNombre: boolean,
    onChange?: (catalogoServicio: CatalogoServicio) => void,
    downloadDocCotizacionPrueba?: (catalogoServicio: CatalogoServicio) => void,
    guardarCambios?: (catalogoServicio: CatalogoServicio) => void,
    cambiarPlantilla?: (formData: FormData) => Promise<{ codigo: number, mensaje: string, object: any }>;
}) {

    const hiddenFileInput = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files.item(0);
            if (file) {
                if (file.size <= 0 || file.size >= FILE_MAX_SIZE) {
                    // @ts-ignore
                    e.target.value = null;
                    showMensajeError("Los archivos deben ser mayor o igual a 1 byte y menor o igual a 80 MB")
                    return;
                }
                //colocamos los valores solo como referencia


                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                setPlantillaDescargable({
                    tamanio: 0,
                    id: '1',
                    subidoPor: getUsuarioSesionUsername(),
                    nombre: file.name,
                    fechaSubido: '',
                    folder: null,
                    dropboxLink: null,
                });
                if (props.catalogoServicio.id !== null) {
                    setSubiendoPlantilla(true);
                    actualizarPlantillaCotizacion(props.catalogoServicio.id, formData).then(resp => {
                        if (resp.statusCode === OK) {
                            setPlantillaDescargable(resp.data);
                        } else {
                            showMensajeError(resp.message)
                        }
                    }).catch(err => {
                        showMensajeError("Lo sentimos ocurrió un error al intentar actualizar la plantilla por favor inténtelo mas tarde")
                    }).finally(() => {
                        setSubiendoPlantilla(false);
                        // @ts-ignore
                        e.target.value = null;

                    })
                }
            }
        }
    };

    const clickSelectFiles = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click();
        }
    }

    const [subiendoPlantilla, setSubiendoPlantilla] = useState<boolean>(false);

    const [confirmarEliminarPlantilla, setConfirmarEliminarPlantilla] = useState<{
        enabled: boolean,
        plantilla: string,
    }>({enabled: false, plantilla: ''});

    const [indiceDetalles, setIndiceDetalles] = useState<number>(-1);

    const [mostrarMensajeError, setMostrarMensajeError] = useState<boolean>(false);

    const [mensajeError, setMensajeError] = useState<string | null>(null);

    const showMensajeError = (mensaje: string) => {
        setMensajeError(mensaje);
        setMostrarMensajeError(true);
    }

    const closeMensajeError = () => {
        setMensajeError(null);
        setMostrarMensajeError(false);
    }

    const [errores, setErrores] = useState<any>([]);

    const [subservicios, setSubservicios] = useState<Subservicio[]>([])
    const [documentos, setDocumentos] = useState<Documento[]>([])
    const [plantillaDescargable, setPlantillaDescargable] = useState<Archivo | null>(null);

    const [guardandoCambios, setGuardandoCambios] = useState<boolean>(false);

    useEffect(() => {
        setErrores({});
        if (props.catalogoServicio) {
            setSubservicios(cloneDeep(props.catalogoServicio.subservicios));
            setDocumentos(cloneDeep(props.catalogoServicio.documentos));
        }
    }, [props.catalogoServicio]);

    function existenErrores(subservicios: Subservicio[]) {
        for (let s = 0; s < subservicios.length; s++) {
            if (subservicios[s].errores && Object.keys(subservicios[s].errores).length > 0) return true;
        }
        for (let s = 0; s < documentos.length; s++) {
            if (documentos[s].errores && Object.keys(documentos[s].errores).length > 0) return true;
        }
        return false;
    }

    const guardarCambios = () => {

        const sumaDescuentos = subservicios.reduce((accumulator: number, subservice: Subservicio) => {
            return accumulator + (subservice.precio * (subservice.porcentajeDescuento / 100));
        }, 0);
        const sumaPrecios = subservicios.reduce((accumulator: number, subservice: Subservicio) => {
            return accumulator + subservice.precio;
        }, 0);
        const precio = sumaPrecios;
        const descuento = sumaDescuentos;
        const iva = (sumaPrecios - sumaDescuentos) * 0.16;
        const subtotal = (sumaPrecios - sumaDescuentos);
        const copia: CatalogoServicio = {
            id: props.catalogoServicio.id,
            prefijoFolio: props.catalogoServicio.prefijoFolio,
            actividades: props.catalogoServicio.actividades,
            descripcion: descripcion.value,
            iva: iva,
            precio: precio,
            descuento: descuento,
            subtotal: subtotal,
            subservicios: cloneDeep(subservicios),
            documentos: cloneDeep(documentos),
        }

        if (props.guardarCambios) {
            props.guardarCambios(copia);
        } else {
            setGuardandoCambios(true);
            actualizarCatalogoServicio(copia).then(resp => {
                if (resp.statusCode === OK) {
                    //setPlantillaDescargable(resp.data.plantillaDescargable);
                    //copia.plantillaDescargable = resp.data.plantillaDescargable;
                    if (props.onChange) {
                        props.onChange(copia);
                    }
                    props.ocultar();
                } else {
                    showMensajeError(resp.message);
                }
            }).catch(err => showMensajeError('Lo sentimos ocurrió un error al intentar actualizar el catalogo de servicio por favor inténtelo mas tarde'))
                .finally(() => {
                    setGuardandoCambios(false);
                });
        }
    }

    const [generarDocumentoAutomatico, setGenerarDocumentoAutomatico] = useState<boolean>(false);

    const descripcion = useFormInput(
        'text',
        props.catalogoServicio.descripcion,
        100,
        'Debe especificar un nombre de servicio válido',
        false,
        true,
        false,
    );

    return (
        <Container>
            {
                props.editarNombre &&
                <Form className="row g-6">
                    <Form.Group controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre del servicio: </Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...descripcion}/>
                        <Form.Control.Feedback type="invalid">{descripcion.error}</Form.Control.Feedback>
                    </Form.Group>
                </Form>

            }


            <Tabs defaultActiveKey='subservicios' id='catalogoServicioTab'
                  className='mb-3 fs-6' fill onSelect={(key)=>{
                      if (key==='plantilla' && props.catalogoServicio.id){
                          fetchTemplateReference(props.catalogoServicio.id).then(resp => {
                              if (resp.statusCode === OK) {
                                  setPlantillaDescargable({...resp.data,url: `/api/catalogo/${props.catalogoServicio.id}/plantilla/${resp.data.id}`});
                              }else{

                              }
                          })
                      }
            }}>
                <Tab eventKey='subservicios' className='fs-6' title='Servicios incluidos'>

                    {
                        subservicios.length === 0 ?
                            <div className="d-flex justify-content-center">
                                <span className='fs-5 fw-bold' style={{color: '#d0cece'}}>Aún no hay subservicios</span>
                            </div> :
                            <ListGroup>
                                <ListGroup.Item className='fw-bold header-subtable' key={'header'}>
                                    <Row className="fw-bold">
                                        <Col>
                                            Descripción
                                        </Col>
                                        <Col sm='2'>
                                            Precio
                                        </Col>
                                        <Col sm='2'>
                                            % Descuento
                                        </Col>
                                        <Col sm='1'></Col>
                                    </Row>
                                </ListGroup.Item>
                                {
                                    subservicios.map((subservicio: Subservicio, index: number) => {
                                        return (
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col sm='7'>
                                                        <EditText
                                                            placeholder={'La descripción del servicio es un campo requerido'}
                                                            style={{width: '100%'}}
                                                            value={subservicio.descripcion}
                                                            onChange={(e) => {
                                                                const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                    if (idx === index) {
                                                                        try {
                                                                            s.descripcion = e.target.value;
                                                                        } catch (err) {
                                                                        }
                                                                    }
                                                                    return s;
                                                                });
                                                                setSubservicios(newSubservicios);
                                                            }}
                                                            onSave={({name, value, previousValue}) => {
                                                                if (value.trim() === '') {
                                                                    const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                        if (idx === index) {
                                                                            if (!s.errores) s.errores = {};
                                                                            s.errores = {
                                                                                ...s.errores,
                                                                                descripcion: 'Se requiere una descripción del subservicio no mayor a 200 caracteres'
                                                                            };
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setSubservicios(newSubservicios);
                                                                } else {
                                                                    const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                        if (idx === index) {
                                                                            if (s.errores) {
                                                                                s.errores = omit(s.errores, 'descripcion')
                                                                            }
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setSubservicios(newSubservicios);
                                                                }
                                                            }}
                                                        />
                                                        {subservicio.errores?.descripcion &&
                                                            <span
                                                                className='errores-validacion'>{subservicio.errores.descripcion}</span>}
                                                    </Col>
                                                    <Col sm='2'>
                                                        <EditText
                                                            formatDisplayText={(value) => formatoMoneda(+value)}
                                                            style={{width: '100%'}}
                                                            value={subservicio.precio.toString()}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                    if (idx === index) {
                                                                        //@ts-ignore
                                                                        s.precio = value;
                                                                    }
                                                                    return s;
                                                                });
                                                                setSubservicios(newSubservicios);

                                                            }}
                                                            onSave={({name, value, previousValue}) => {
                                                                let error;
                                                                let precio = 0;
                                                                try {
                                                                    precio = parseFloat(value);
                                                                    error = (precio <= 0 || isNaN(precio))
                                                                } catch (err) {
                                                                    error = true;
                                                                }
                                                                if (error) {
                                                                    const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                        if (idx === index) {
                                                                            if (!s.errores) s.errores = {};
                                                                            s.errores = {
                                                                                ...s.errores,
                                                                                precio: 'Precio del subservicio es inválido'
                                                                            };
                                                                            s.precio = 0;
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setSubservicios(newSubservicios);
                                                                } else {
                                                                    const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                        if (idx === index) {
                                                                            if (s.errores) {
                                                                                s.errores = omit(s.errores, 'precio')
                                                                            }
                                                                            s.precio = precio;
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setSubservicios(newSubservicios);
                                                                }
                                                            }}
                                                        />
                                                        {subservicio.errores?.precio && <span
                                                            className='errores-validacion'>{subservicio.errores.precio}</span>}
                                                    </Col>
                                                    <Col sm='2'>
                                                        <EditText
                                                            style={{width: '100%'}}
                                                            formatDisplayText={(value) => `${value}%`}
                                                            value={subservicio.porcentajeDescuento.toString()}
                                                            onChange={(e) => {
                                                                const discountString = e.target.value;
                                                                try {
                                                                    const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                        if (idx === index) {
                                                                            //@ts-ignore
                                                                            s.porcentajeDescuento = discountString;
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setSubservicios(newSubservicios);
                                                                } catch (e) {
                                                                }
                                                            }}
                                                            onSave={({name, value, previousValue}) => {
                                                                let error = false;
                                                                let percentageDiscount = 0;
                                                                try {
                                                                    percentageDiscount = parseInt(value);
                                                                    error = (percentageDiscount < 0 || percentageDiscount > 100)
                                                                } catch (err) {
                                                                    error = true;
                                                                }
                                                                if (error) {
                                                                    const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                        if (idx === index) {
                                                                            if (!s.errores) s.errores = {};
                                                                            s.errores = {
                                                                                ...s.errores,
                                                                                porcentajeDescuento: 'Porcentaje de descuento inválido',
                                                                            };
                                                                            s.porcentajeDescuento = 0;
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setSubservicios(newSubservicios);
                                                                } else {
                                                                    const newSubservicios = subservicios.map((s: Subservicio, idx: number) => {
                                                                        if (idx === index) {
                                                                            if (s.errores) {
                                                                                s.errores = omit(s.errores, 'porcentajeDescuento')
                                                                            }
                                                                            s.porcentajeDescuento = percentageDiscount;
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setSubservicios(newSubservicios);
                                                                }
                                                            }}

                                                        />
                                                        {subservicio.errores?.porcentajeDescuento && <span
                                                            className='errores-validacion'>{subservicio.errores.porcentajeDescuento}</span>}
                                                    </Col>
                                                    <Col sm='1'>
                                                        <div className='d-flex justify-content-end'>
                                                            <DeleteButton onClick={() => {
                                                                const newSubservicios = subservicios.filter((item: Subservicio, i: number) => {
                                                                    if (index !== i) return item;
                                                                })
                                                                setSubservicios(newSubservicios);
                                                            }}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div>
                                                                        <span
                                                                            className={`bi ${indiceDetalles === index ? 'bi-dash-circle-fill' : 'bi-plus-circle-fill'} folderName`}
                                                                            style={{fontSize: '0.8rem'}}
                                                                            onClick={() => {
                                                                                if (indiceDetalles === index) setIndiceDetalles(-1);
                                                                                else setIndiceDetalles(index)
                                                                            }}>
                                                                                {indiceDetalles === index ? ' Ocultar detalles...' : ' Ver detalles...'}
                                                                        </span>
                                                        </div>
                                                        {
                                                            indiceDetalles === index &&
                                                            <div style={{marginLeft: '15px'}}>
                                                                {
                                                                    subservicio.tareas.map((item: Tarea, idx) => {
                                                                        return (<TareaItem tarea={item}
                                                                                           eliminable={true}
                                                                                           onChange={(value) => {
                                                                                               const newTareas = subservicio.tareas.map((t, g) => {
                                                                                                   if (g === idx) {
                                                                                                       t.descripcion = value;
                                                                                                   }
                                                                                                   return t;
                                                                                               });
                                                                                               setSubservicios(subservicios.map((s, idx) => {
                                                                                                   if (idx === index) {
                                                                                                       s.tareas = newTareas;
                                                                                                   }
                                                                                                   return s;
                                                                                               }));
                                                                                           }}
                                                                                           onDelete={() => {
                                                                                               const newTareas = subservicio.tareas.filter((t, g) => g != idx);
                                                                                               setSubservicios(subservicios.map((s, idx) => {
                                                                                                   if (idx === index) {
                                                                                                       s.tareas = newTareas;
                                                                                                   }
                                                                                                   return s;
                                                                                               }));
                                                                                           }}/>)
                                                                    })
                                                                }
                                                                <div>
                                                                        <span className='bi bi-plus-circle folderName'
                                                                              style={{fontSize: '0.8rem'}}
                                                                              onClick={() => {

                                                                                  const newTareas = cloneDeep(subservicio.tareas);
                                                                                  newTareas.push({
                                                                                      descripcion: 'Nueva tarea'
                                                                                  });
                                                                                  setSubservicios(subservicios.map((s, idx) => {
                                                                                      if (idx === index) {
                                                                                          s.tareas = newTareas;
                                                                                      }
                                                                                      return s;
                                                                                  }));

                                                                              }}> Agregar...
                                                                        </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                    }

                    <div style={{marginTop: '10px'}}>
                        <Button size='sm' variant='outline-dark' onClick={() => {
                            setSubservicios([...subservicios,
                                {
                                    descripcion: 'Nuevo subservicio',
                                    porcentajeDescuento: 0,
                                    precio: 0,
                                    tareas: [],
                                    unidades: "",
                                }]);
                        }}><i className='bi bi-plus'/> Agregar nuevo subservicio</Button>
                    </div>

                    <div style={{marginTop: '10px', marginBottom: '10px'}}>
                        <div className='d-flex justify-content-end'>
                                  <span
                                      className='fw-bold fs-4'>Subtotal: {formatoMoneda(getSumaSubtotalSubservicios(subservicios))}</span><span
                            style={{fontSize: '0.7em'}}>Sin iva</span>
                        </div>
                    </div>
                    <hr/>
                    <div className='d-flex justify-content-end'
                         style={{
                             marginTop: '10px',
                             paddingTop: '10px'
                         }}>
                        <Button size='sm' variant='danger' style={{width: '200px'}}
                                onClick={props.ocultar}>Cancelar</Button>
                        <Button size='sm' variant='dark' style={{width: '200px'}}
                                disabled={Object.keys(errores).length !== 0 || existenErrores(subservicios)}
                                onClick={guardarCambios}>
                            {
                                guardandoCambios ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /><span>Guardando cambios...</span>
                                </> : <>Guardar cambios</>
                            }
                        </Button>
                    </div>
                </Tab>
                <Tab eventKey='documentos' className='fs-6' title='Documentos Solicitados'>
                    <ListGroup>
                        <ListGroup.Item className='fw-bold header-subtable' key={'header'}>
                            <Row className="fw-bold">
                                <Col className="col-10">
                                    Documentos
                                </Col>
                                <Col>
                                    Opciones
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        {
                            documentos.map((documento: Documento, index: number) => {
                                return (
                                    <ListGroup.Item>
                                        <Row>
                                            <Col className="col-10">
                                                {
                                                    documento.clave === 'comprobante.pago' ?
                                                        <div
                                                            style={{width: '100%'}}>
                                                            <div className='d-inline-flex'>
                                                                <div style={{
                                                                    marginTop: '5px',
                                                                    marginRight: '5px'
                                                                }}>
                                                                    <img src="/folder-icon.svg"
                                                                         alt="folder image"
                                                                         width='28' height='28'/>
                                                                </div>
                                                                <div>
                                                                    <label style={{
                                                                        marginTop: '5px',
                                                                        marginLeft: '5px'
                                                                    }}>{documento.nombre}</label>
                                                                </div>
                                                            </div>

                                                            <div style={{marginLeft: '40px'}}>
                                                                <label className='file-size-label'>(La carpeta para
                                                                    comprobantes de pago es requerida en todos los
                                                                    servicios)</label>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='d-inline-flex'
                                                             style={{width: '100%'}}>
                                                            <div style={{
                                                                marginTop: '5px',
                                                                marginRight: '5px'
                                                            }}>
                                                                <img src="/folder-icon.svg"
                                                                     alt="folder image"
                                                                     width='28' height='28'/>
                                                            </div>

                                                            <EditText
                                                                formatDisplayText={(value) => truncarTexto(value, 70)}
                                                                value={documento.nombre}
                                                                onChange={(e) => {
                                                                    const newDocumentos = documentos.map((s: Documento, idx: number) => {
                                                                        if (idx === index) {
                                                                            s.nombre = e.target.value;
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setDocumentos(newDocumentos);
                                                                }}
                                                                onSave={({name, value, previousValue}) => {
                                                                    if (!value || value.trim() === '') {
                                                                        const newDocumentos = cloneDeep(documentos);
                                                                        let doc = newDocumentos[index];
                                                                        doc.errores = {
                                                                            nombre: 'Nombre de documento inválido',
                                                                        }
                                                                        setDocumentos(newDocumentos);

                                                                    } else {
                                                                        const newDocumentos = cloneDeep(documentos);
                                                                        let doc = newDocumentos[index];
                                                                        doc.errores = null;
                                                                        setDocumentos(newDocumentos);
                                                                    }
                                                                }}
                                                            /></div>
                                                }
                                                {documento.errores?.nombre && <span
                                                    className='errores-validacion'>{documento.errores.nombre}</span>}
                                            </Col>
                                            <Col className="d-flex justify-content-center">
                                                {
                                                    documento.clave === 'comprobante.pago' ? <></> :
                                                        <DeleteButton onClick={() => {
                                                            const newDocumentos = documentos.filter((item: Documento, i: number) => {
                                                                if (index !== i) return item;
                                                            })

                                                            setDocumentos(cloneDeep(newDocumentos));
                                                        }}/>
                                                }
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                    <div style={{marginTop: '10px'}}>
                        <Button size='sm' variant='outline-dark' onClick={() => {
                            const newDocumentos: Documento[] = [...documentos];
                            newDocumentos.push({
                                archivos: [],
                                nombre: 'Nuevo documento'
                            })
                            setDocumentos(newDocumentos);
                        }}><i className='bi bi-plus'/> Agregar nuevo documento</Button>
                    </div>
                    <hr/>
                    <div className='d-flex justify-content-end'
                         style={{
                             marginTop: '10px',
                             paddingTop: '10px'
                         }}>
                        <Button size='sm' variant='danger' style={{width: '200px'}}
                                onClick={props.ocultar}>Cancelar</Button>
                        <Button size='sm' variant='dark' style={{width: '200px'}}
                                disabled={Object.keys(errores).length !== 0 || existenErrores(subservicios)}
                                onClick={guardarCambios}>
                            {
                                guardandoCambios ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /><span>Guardando cambios...</span>
                                </> : <>Guardar cambios</>
                            }
                        </Button>
                    </div>
                </Tab>

                <Tab eventKey='plantilla' className='fs-6' title='Plantilla de Cotización'>
                    {
                        !generarDocumentoAutomatico && <>

                            <p style={{fontSize: "0.9em", marginTop: "10px", color: '#3a3939'}}>
                                * El documento de word que especifique se
                                usará para generar el archivo de
                                cotización de forma predeterminada al enviar una cotización
                            </p>

                            <input id="file" type="file" accept=".docx" multiple={false} ref={hiddenFileInput}
                                   style={{display: 'none'}}
                                   onChange={handleFileChange}/>
                            <div>
                                {
                                    plantillaDescargable ?
                                        <div style={{marginTop: '10px', marginLeft: '10px'}}>
                                            <ArchivoItem archivo={plantillaDescargable}
                                                         servicioId={''}
                                                         documentoId={''}
                                                         statusServicio={'terminado'}
                                                         seleccionable={false} eliminable={false}
                                                         onDelete={(archivo: Archivo) => {
                                                             setConfirmarEliminarPlantilla({
                                                                 enabled: true,
                                                                 plantilla: archivo.nombre
                                                             });
                                                         }}/>
                                        </div> :
                                        <RoundedDashedBox label='Aún no se ha especificado una plantilla para cotización'/>
                                }

                                <div className='d-flex justify-content-end'
                                     style={{marginTop: '20px', marginBottom: '20px'}}>
                                    <Button size='sm' variant='danger' style={{width: '200px'}}
                                            onClick={props.ocultar}>Cancelar</Button>
                                    <Button variant='primary' size='sm' style={{width: '200px'}} onClick={clickSelectFiles}
                                            disabled={subiendoPlantilla}>
                                        {
                                            subiendoPlantilla ? <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /><span>Actualizando plantilla...</span>
                                            </> : <>{plantillaDescargable ? 'Actualizar' : 'Agregar'} plantilla</>
                                        }

                                    </Button>
                                </div>
                            </div>

                        </>
                    }
                </Tab>

            </Tabs>

            {
                mostrarMensajeError &&
                <div style={{marginTop: '10px'}}>
                    <Alert key="danger" variant="danger" onClose={closeMensajeError} show={true}
                           style={{fontSize: '0.8em'}}
                           dismissible>
                        {mensajeError}
                    </Alert>
                </div>
            }


            <ConfirmacionModal show={confirmarEliminarPlantilla.enabled}
                               onCancel={() => setConfirmarEliminarPlantilla({enabled: false, plantilla: ''})}
                               title={'Confirmación'} onAccept={() => {

                setConfirmarEliminarPlantilla({enabled: false, plantilla: ''});
                if (plantillaDescargable) {
                    eliminarArchivo(plantillaDescargable).then(resp => {
                        if (resp.statusCode === OK) {
                            setPlantillaDescargable(null);
                        } else {
                            showMensajeError(resp.message);
                        }
                    }).catch(err => {
                    }).finally(() => {
                    });
                }
            }} message={[`Esta seguro de eliminar la plantilla ${confirmarEliminarPlantilla.plantilla}`]}/>
        </Container>
    )
}