import {Button, Col, Container, ListGroup, ListGroupItem, Pagination, Row} from "react-bootstrap";
import PageHeader from "../common/PageHeader";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "../common/LoadingSpinner";
import {CatalogoServicio, ServiceCatalogPagination, Subservicio} from "../../types/servicio";
import {formatoMoneda, OK} from "../../utils";
import {
    actualizarCatalogoServicio,
    crearCatalogoServicio,
    eliminarCatalogoServicio,
    getCatalogoServicios
} from "../../services/conem-services";
import 'react-edit-text/dist/index.css';
import ConfirmacionModal from "../common/ConfirmacionModal";
import Registros from "../paginacion/Registros";
import FormCatalogoServicio from "./FormCatalogoServicio";
import {omit} from "lodash";
import {EditText} from "react-edit-text";
import cloneDeep from "lodash.clonedeep";

export default function CatalogoServicioList() {

    const [indexOpen, setIndexOpen] = useState<number>(-1);

    const [catalogoServicios, setCatalogoServicios] = useState<CatalogoServicio[]>([]);
    const [catalogoServicioSeleccionado, setCatalogoServicioSeleccionado] = useState<CatalogoServicio | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [confirmarEliminar, setConfirmarEliminar] = useState<boolean>(false);

    const ROWS_PER_PAGE = 5;
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [paginacion, setPaginacion] = useState<ServiceCatalogPagination>({
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
        page: currentPage,
        items: []
    });

    const cargarCatalogoServicios = (pagination: { page: number, rowsPerPage: number }) => {
        setLoading(true);
        setCurrentPage(pagination.page);
        getCatalogoServicios(pagination).then(data => {
            const catalogoServicios = data.items;
            for (let servicio of catalogoServicios) {
                const sumaDescuentos = servicio?.subservicios.reduce((accumulator: number, subservice: Subservicio) => {
                    return accumulator + (subservice.precio * (subservice.porcentajeDescuento / 100));
                }, 0);
                const sumaPrecios = servicio?.subservicios.reduce((accumulator: number, subservice: Subservicio) => {
                    return accumulator + subservice.precio;
                }, 0);
                servicio.precio = sumaPrecios;
                servicio.descuento = sumaDescuentos;
                servicio.iva = (sumaPrecios - sumaDescuentos) * 0.16;
                servicio.subtotal = (sumaPrecios - sumaDescuentos) + servicio.iva;
            }
            setPaginacion(data);
            setCatalogoServicios(catalogoServicios);
        }).catch(error => {
            //console.log(error);
        }).finally(() => {
            setLoading(false)
        });
    };

    useEffect(() => {
        cargarCatalogoServicios({page: 0, rowsPerPage: ROWS_PER_PAGE});
    }, []);

    const handleChangePage = (newPage: number) => {
        cargarCatalogoServicios({page: newPage, rowsPerPage: ROWS_PER_PAGE});
    };

    return (<>
        <LoadingSpinner show={loading}/>
        <Container>
            <PageHeader label="Catálogo de Servicios"/>
            <p style={{fontSize: "0.9em", marginTop: "10px", color: '#3a3939'}}>
                Los servicios, subservicios, precios y características específicados en este apartado sirven como
                referencia para permitir generar de forma rápida las cotizaciones sin embargo la mayoría de estos
                valores pueden ser editados durante la creación de
                la cotización.
            </p>
            <Row>
                <Col>
                    <Button variant='outline-dark' size='sm' onClick={() => {

                        const newItem: CatalogoServicio = {
                            id: null,
                            subtotal: 0,
                            iva: 0,
                            descripcion: "Nuevo servicio",
                            descuento: 0,
                            precio: 0,
                            prefijoFolio: "SCP",
                            actividades: [
                                {
                                    clave: "reporte.inspeccion",
                                    descripcion: "Reporte de inspección",
                                    status: 'por_iniciar',
                                    evidencias: {
                                        nombre: "Reporte de Inspección",
                                        verificado: false,
                                        archivos: []
                                    },
                                },
                                {
                                    clave: "validacion.informacion",
                                    status: 'por_iniciar',
                                    descripcion: "Validación de la información",
                                    evidencias: {
                                        nombre: "Validación de la información",
                                        verificado: false,
                                        archivos: []
                                    },
                                },
                                {
                                    clave: "desarrollo.pipc",
                                    status: 'por_iniciar',
                                    descripcion: "Desarrollo del Programa Interno de Protección Civil",
                                    evidencias: {
                                        nombre: "Desarrollo del Programa Interno de Protección Civil",
                                        verificado: false,
                                        archivos: []
                                    },
                                },
                                {
                                    clave: "revision.calidad",
                                    status: 'por_iniciar',
                                    descripcion: "Revisión de Calidad",
                                    evidencias: {
                                        nombre: "Revisión de Calidad",
                                        verificado: false,
                                        archivos: []
                                    },
                                    intentos: 0,
                                },
                                {
                                    clave: "ingreso.pc",
                                    status: 'por_iniciar',
                                    descripcion: "Ingreso a Protección Civil",
                                    evidencias: {
                                        nombre: "Ingreso a Protección Civil",
                                        verificado: false,
                                        archivos: []
                                    }
                                },
                                {
                                    clave: "liberacion.anuencia",
                                    status: 'por_iniciar',
                                    descripcion: "Liberación de Anuencia",
                                    evidencias: {
                                        nombre: "Liberacion de Anuencia - evidencias",
                                        verificado: false,
                                        archivos: []
                                    },
                                },
                                {
                                    clave: "entrega.cliente",
                                    status: 'por_iniciar',
                                    descripcion: "Entrega al Cliente",
                                    evidencias: {
                                        nombre: "Entrega al Cliente",
                                        verificado: false,
                                        archivos: []
                                    },
                                }
                            ],
                            documentos: [
                                {
                                    clave: "comprobante.pago",
                                    nombre: "Comprobantes de pago",
                                    verificado: false,
                                    archivos: [],
                                },
                                {
                                    nombre: "Póliza de seguro vigente",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Dictamen Eléctrico",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Dictamen Estructural",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Dictamen de Gas",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Responsiva Extintores",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Planos del inmueble",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Documentos Firmados",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Listado de todo el personal",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Listado específico del personal que formará parte de la Unidad Interna de Protección Civil",
                                    verificado: false,
                                    archivos: []
                                },
                                {
                                    nombre: "Contrato de arrendamiento",
                                    verificado: false,
                                    archivos: []
                                }
                            ],
                            subservicios: [
                                {
                                    descripcion: "DESARROLLO DE PROGRAMA INTERNO DE PROTECCIÓN CIVIL",
                                    precio: 9200,
                                    unidades: "1",
                                    porcentajeDescuento: 0,
                                    tareas: [
                                        {
                                            descripcion: " Recorrido de las instalaciones para la verificación de riesgos internos. (Físicos, químicos, biológicos, ergonómicos y mecánicos)"
                                        },
                                        {
                                            descripcion: "Desarrollo de planes de emergencia específicos"
                                        },
                                        {
                                            descripcion: "Integración de Documentación para la entrega de protección civil."
                                        },
                                        {
                                            descripcion: "Análisis de Riesgos Internos y externos"
                                        },
                                        {
                                            descripcion: "Realización de plano con ubicación de elementos de emergencia."
                                        },
                                        {
                                            descripcion: "Desarrollo de Croquis de Zonas de Riesgo, localización de señalamientos y equipo de seguridad."
                                        },
                                        {
                                            descripcion: "Cálculo del tiempo de respuesta de las unidades de Emergencia"
                                        },
                                        {
                                            descripcion: "Determinación básica del grado de riesgo de incendio."
                                        },
                                        {
                                            descripcion: "Propuesta de calendarios de mantenimientos, simulacros y capacitación."
                                        },
                                        {
                                            descripcion: "Validación 2024 del programa interno de protección civil por medio de carta de corresponsabilidad tercer acreditado."
                                        }
                                    ]
                                },
                                {
                                    descripcion: "GESTORÍA DE TRÁMITES PARA LA LIBERACIÓN DE ANUENCIA/VISTO BUENO DE PROTECCIÓN CIVIL",
                                    precio: 0,
                                    unidades: "1",
                                    porcentajeDescuento: 0,
                                    tareas: [
                                        {
                                            descripcion: "Ingreso de Programa Interno de Protección Civil e integración de Anexos correspondientes solicitados por la autoridad."
                                        },
                                        {
                                            descripcion: "Seguimiento para la liberación."
                                        },
                                        {
                                            descripcion: "Solicitud de Papeleta de Pago de Derechos."
                                        },
                                        {
                                            descripcion: "Canje de Pago de derechos ante la autoridad"
                                        },
                                        {
                                            descripcion: "Recolección y entrega al cliente el Visto Bueno."
                                        },
                                        {
                                            descripcion: "Duración: 8 hrs Nos adaptamos a su disponibilidad"
                                        }
                                    ]
                                },
                                {
                                    descripcion: "CAPACITACIÓN DE BRIGADAS INTERNAS DE PROTECCIÓN CIVIL",
                                    precio: 6800,
                                    unidades: "Grupo empresarial Grupo máximo 20 personas ",
                                    porcentajeDescuento: 0,
                                    tareas: [
                                        {
                                            descripcion: "Modalidad: Presencial / Teórico-Practico"
                                        },
                                        {
                                            descripcion: "Primeros Auxilios: Práctica de RCP, Vendaje, Maniobra de Heimlich."
                                        },
                                        {
                                            descripcion: "Prevención y combate de Incendios: Práctica de descarga del extintor."
                                        },
                                        {
                                            descripcion: "Evacuación de inmuebles."
                                        },
                                        {
                                            descripcion: "Búsqueda y Rescate."
                                        }
                                    ]
                                }
                           ]};
                        crearCatalogoServicio(newItem).then(resp => {
                            if (resp.statusCode === OK) {
                                const newCatalogoServicios = cloneDeep(catalogoServicios);
                                newCatalogoServicios.push(resp.data);
                                setCatalogoServicios(newCatalogoServicios);
                            } else {
                                alert(resp.message);
                            }
                        }).catch(err => {
                            alert(err);
                        }).finally(() => {

                        })

                    }}><i className="bi bi-plus"/> Agregar servicio
                    </Button>
                    <Button variant='outline-dark' size='sm' style={{marginLeft: '10px'}} onClick={async () => {
                        cargarCatalogoServicios({page: 0, rowsPerPage: ROWS_PER_PAGE});
                    }}><i className="bi bi-arrow-repeat"/> Actualizar lista
                    </Button>
                </Col>
                <Col>
                    {
                        paginacion.numberOfElements > 0 &&
                        <Registros registros={`
                        Registros ${((paginacion.page) * ROWS_PER_PAGE) + 1} al ${((paginacion.page) * ROWS_PER_PAGE) + paginacion.numberOfElements} - Página ${paginacion.page + 1} de ${paginacion.totalPages} - Total de registros ${paginacion.totalElements}
                        `}/>
                    }
                </Col>
            </Row>
            <div style={{marginTop: '10px'}}>
                <ListGroup>
                    <ListGroup.Item className='fw-bold' style={{backgroundColor: '#3a3939', color: 'white'}}
                                    key={'header'}>
                        <Row>
                            <Col sm='3'>Nombre del servicio</Col>
                            <Col sm='2'>Prefijo Folio</Col>
                            <Col>Precio</Col>
                            <Col>Descuento</Col>
                            <Col>IVA</Col>
                            <Col>Subtotal</Col>
                            <Col sm='2'>Acciones</Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        catalogoServicios.map((catalogoServicio: CatalogoServicio, i: number) => (
                            <ListGroupItem>
                                <Row>
                                    <Col sm="3">
                                        <EditText
                                            placeholder={'Descripción o nombre del subservicio'}
                                            style={{width: '100%'}}
                                            value={catalogoServicio.descripcion}
                                            onChange={(e) => {
                                                const newCatalogoServicios = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                    if (idx === i) {
                                                        try {
                                                            s.descripcion = e.target.value;
                                                        } catch (err) {
                                                        }
                                                    }
                                                    return s;
                                                });
                                                setCatalogoServicios(newCatalogoServicios);
                                            }}
                                            onSave={({name, value, previousValue}) => {
                                                if (value.trim() === '') {
                                                    const catalogoSubservicios = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                        if (idx === i) {
                                                            if (!s.errores) s.errores = {};
                                                            s.errores = {
                                                                ...s.errores,
                                                                descripcion: 'Se requiere una descripción del servicio no mayor a 200 caracteres'
                                                            };
                                                        }
                                                        return s;
                                                    });
                                                    setCatalogoServicios(catalogoSubservicios)
                                                } else {
                                                    catalogoServicio.descripcion = value;
                                                    actualizarCatalogoServicio(catalogoServicio).then(resp => {
                                                        if (resp.statusCode !== 200) {
                                                            const newCatalogo = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                                if (idx === i) {
                                                                    if (!s.errores) s.errores = {};
                                                                    s.errores = {
                                                                        ...s.errores,
                                                                        descripcion: resp.message
                                                                    };
                                                                }
                                                                return s;
                                                            });
                                                            setCatalogoServicios(newCatalogo)
                                                        } else {
                                                            const newCatalogo = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                                if (idx === i) {
                                                                    if (s.errores) {
                                                                        s.errores = omit(s.errores, 'descripcion')
                                                                    }
                                                                }
                                                                return s;
                                                            });
                                                            setCatalogoServicios(newCatalogo)
                                                        }
                                                    }).catch(err => {
                                                    }).finally(() => {
                                                    })
                                                }
                                            }}
                                        />
                                        {catalogoServicio.errores?.descripcion &&
                                            <span
                                                className='errores-validacion'>{catalogoServicio.errores.descripcion}</span>}
                                    </Col>
                                    <Col sm="2">
                                        <EditText
                                            placeholder={'Prefijo del folio'}
                                            style={{width: '100%'}}
                                            value={catalogoServicio.prefijoFolio}
                                            onChange={(e) => {
                                                const newCatalogoServicios = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                    if (idx === i) {
                                                        try {
                                                            s.prefijoFolio = e.target.value;
                                                        } catch (err) {
                                                        }
                                                    }
                                                    return s;
                                                });
                                                setCatalogoServicios(newCatalogoServicios);
                                            }}
                                            onSave={({name, value, previousValue}) => {
                                                if (value.trim() === '') {
                                                    const catalogoSubservicios = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                        if (idx === i) {
                                                            if (!s.errores) s.errores = {};
                                                            s.errores = {
                                                                ...s.errores,
                                                                prefijoFolio: 'Se requiere especificar el prefijo del folio'
                                                            };
                                                        }
                                                        return s;
                                                    });
                                                    setCatalogoServicios(catalogoSubservicios)
                                                } else {
                                                    catalogoServicio.prefijoFolio = value;
                                                    actualizarCatalogoServicio(catalogoServicio).then(resp => {
                                                        if (resp.statusCode !== 200) {
                                                            const newCatalogo = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                                if (idx === i) {
                                                                    if (!s.errores) s.errores = {};
                                                                    s.errores = {
                                                                        ...s.errores,
                                                                        prefijoFolio: resp.message
                                                                    };
                                                                }
                                                                return s;
                                                            });
                                                            setCatalogoServicios(newCatalogo)
                                                        } else {
                                                            const newCatalogo = catalogoServicios.map((s: CatalogoServicio, idx: number) => {
                                                                if (idx === i) {
                                                                    if (s.errores) {
                                                                        s.errores = omit(s.errores, 'prefijoFolio')
                                                                    }
                                                                }
                                                                return s;
                                                            });
                                                            setCatalogoServicios(newCatalogo)
                                                        }
                                                    }).catch(err => {
                                                    }).finally(() => {
                                                    })
                                                }
                                            }}
                                        />
                                        {catalogoServicio.errores?.prefijoFolio &&
                                            <span
                                                className='errores-validacion'>{catalogoServicio.errores.prefijoFolio}</span>}
                                    </Col>
                                    <Col>
                                        <label>{formatoMoneda(catalogoServicio.precio)}</label>
                                    </Col>
                                    <Col>
                                        <label>{formatoMoneda(catalogoServicio.descuento)}</label>
                                    </Col>
                                    <Col>
                                        <label>{formatoMoneda(catalogoServicio.iva)}</label>
                                    </Col>
                                    <Col>
                                        <label>{formatoMoneda(catalogoServicio.subtotal)}</label>
                                    </Col>
                                    <Col sm='2'>
                                        <div className='d-flex justify-content-end'>
                                            <a href='#' onClick={() => {
                                                setCatalogoServicioSeleccionado(catalogoServicio);
                                                setConfirmarEliminar(true);
                                            }}>Eliminar</a>
                                            <a href='#' onClick={() => setIndexOpen(i)}>Editar</a>
                                        </div>
                                    </Col>
                                </Row>

                                {
                                    i === indexOpen &&
                                    <div style={{marginTop: '10px', padding: '10px', borderLeft: '3px solid #fafafa'}}>
                                        <FormCatalogoServicio editarNombre={false}
                                                              enableTestFile={false}
                                                              catalogoServicio={catalogoServicio}
                                                              ocultar={() => {
                                                                  setIndexOpen(-1);
                                                              }}
                                                              onChange={(newCatalogoServicio: CatalogoServicio) => {
                                                                  const newCatalogoServicios = catalogoServicios.map((s: CatalogoServicio) => {
                                                                      if (s.id === newCatalogoServicio.id) {
                                                                          return newCatalogoServicio;
                                                                      }
                                                                      return s;
                                                                  });
                                                                  setCatalogoServicios(newCatalogoServicios);
                                                              }}
                                        />
                                    </div>
                                }
                            </ListGroupItem>
                        ))
                    }
                </ListGroup>
            </div>
            <div className="d-flex justify-content-center" style={{marginTop: "10px"}}>
                <Pagination>
                    {paginacion.totalPages > 1 && Array(paginacion.totalPages).fill(1).map((el, i) =>
                        <Pagination.Item active={i === paginacion.page} onClick={async () => {
                            if (i !== paginacion.page) {
                                setLoading(true)
                                handleChangePage(i);
                            }
                        }}>{i + 1}</Pagination.Item>
                    )}
                </Pagination>
            </div>

        </Container>

        <ConfirmacionModal show={confirmarEliminar} onCancel={() => {
            setConfirmarEliminar(false);
        }}
                           title={'Confirmación'}
                           onAccept={async () => {
                               if (catalogoServicioSeleccionado && catalogoServicioSeleccionado.id) {
                                   try {
                                       setLoading(true);
                                       const response = await eliminarCatalogoServicio(catalogoServicioSeleccionado.id);
                                       if (response.statusCode === OK) {
                                           cargarCatalogoServicios({
                                               page: currentPage,
                                               rowsPerPage: ROWS_PER_PAGE
                                           });
                                       } else {
                                           alert(response.message)
                                       }
                                       setConfirmarEliminar(false);
                                   } catch (err) {
                                       console.log(err);
                                   } finally {
                                       setCatalogoServicioSeleccionado(null);
                                       setLoading(false);
                                   }
                               }

                           }}
                           message={[`¿Está seguro de eliminar el servicio "${catalogoServicioSeleccionado?.descripcion}"?`]}/>
    </>);
}