
import axios from "axios";
import {AxiosProgressEvent} from "axios";
import {Archivo, Documento, Servicio} from "../types/servicio";
import {Response} from "../types/response.type";

export async function uploadFiles(servicioId: string, documentoId: string, formData: FormData, trackProgress: (progressEvent: AxiosProgressEvent) => void) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/documentos/${documentoId}`,
        formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress:trackProgress,
        });
    return data;
}

export async function deleteFile(archivo: Archivo):Promise<Response<any>> {
        const {data} = await axios.post(archivo.url + "/eliminar", {}, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return data;
}

export async function eliminarArchivos(servicio: Servicio, documento: Documento, archivos: Archivo[]) {
    const {data} = await axios.post(`/api/servicios/${servicio.id}/documentos/${documento.id}/archivos/eliminar`, {
        archivos: archivos
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
}