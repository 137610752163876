import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Navbar from "../common/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import FormLogin from "./FormLogin";
import FormRecuperarContrasenia from "./FormRecuperarContrasenia";
import {getUsuarioSesion} from "../../utils";

const Login = () => {

    const navigate = useNavigate();

    const [showLogin, setShowLogin] = useState(true)
    const [recuperarContrasenia, setRecuperarContrasenia] = useState(false)

    useEffect(() => {
        const usuario = getUsuarioSesion();
        if (usuario) {
            navigate("/home");
        }
    }, []);

    return (
        <React.Fragment>
            <Navbar></Navbar>
            <Row className="align-items-center" style={{height: '65vh'}}>
                <Col>
                    <Container style={{
                        width: "30rem", padding: "30px",
                        border: "1px solid rgb(204, 201, 201)",
                        borderRadius: "25px", backgroundColor: "#FAFAFA"
                    }}>
                        <FormLogin show={showLogin}
                                   crearCuenta={() => {
                                       setShowLogin(false)
                                   }}
                                   recuperarPassword={() => {
                                       setShowLogin(false)
                                       setRecuperarContrasenia(true)
                                   }}
                        />
                        {
                            <FormRecuperarContrasenia show={recuperarContrasenia} onCancel={
                                () => {
                                    setShowLogin(true);
                                    setRecuperarContrasenia(false)
                                }
                            }/>
                        }
                    </Container>
                </Col>
            </Row>
            <Container style={{
                position: "fixed", left: "0", bottom: "0", maxWidth: "100%",
                backgroundColor: "black", color: "white", height: "15%",
                paddingTop: "4%"
            }}>
                <div className="text-white d-flex justify-content-center">
                    <p>Powered by <a href="#" target="_blank">Shark Technologies</a></p>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default Login;
