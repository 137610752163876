import React, {useEffect, useState} from "react";
import {Button, Col, Container, Dropdown, DropdownButton, ListGroup, Row, Tab, Tabs} from "react-bootstrap";
import {
    getCotizacionesByMes,
    getGrupoAtencion,
    listGroups
} from "../../services/conem-services";
import ModalMessage from "../common/ModalMessage";
import {getAnioActual, getMesActual, getStatusLabel, getTipoLabel, isAdmin, isVentas} from "../../utils";
import {Grupo, Servicio, Usuario} from "../../types/servicio";
import LoadingSpinner from "../common/LoadingSpinner";
import Loading from "../common/Loading";
import IndicadorItem from "./IndicadorItem";
import {DashboardAdministrativo} from "./DashboardAdministrativo";
import {DashboardOperativo} from "./DashboardOperativo";
import DownloadButton from "../download-button/DownloadButton";

const Dashboard = () => {

    const anioActual: number = getAnioActual();
    const [anioSeleccionado, setAnioSeleccionado] = useState<number>(anioActual);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState<Grupo | null>(null);
    const [grupos, setGrupos] = useState<Grupo[] | null>(null);

    const [grupoAtencionUsuario,setGrupoAtencionUsuario] = useState<Grupo | null>(null);



    const [loading, setLoading] = useState<boolean>(false);
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre',
        'Octubre', 'Noviembre', 'Diciembre'];
    const mesActual: number = getMesActual();
    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [cargandoCotizacionesByMes, setCargandoCotizacionesByMes] = useState<boolean>(false);
    const [estadisticaAnio, setEstadisticaAnio] = useState({
        ventasConcretadas: [null, null, null, null, null, null, null, null, null, null, null, null],
        cotizacionesDeclinadas: [null, null, null, null, null, null, null, null, null, null, null, null],
        cotizacionesEnProceso: [null, null, null, null, null, null, null, null, null, null, null, null],
        cotizacionesEnviadas: [null, null, null, null, null, null, null, null, null, null, null, null],
        porcentajeConversion: [null, null, null, null, null, null, null, null, null, null, null, null],
        porcentajeCancelacion: [null, null, null, null, null, null, null, null, null, null, null, null],

    });
    const [mesSeleccionado, setMesSeleccionado] = useState<number>(mesActual);
    const [nombreMesSeleccionado, setNombreMesSeleccionado] = useState<string>(meses[mesActual]);
    const [estadisticaMes, setEstadisticaMes] = useState<{
        ventasConcretadas: number,
        cotizacionesDeclinadas: number,
        cotizacionesProceso: number,
        cotizacionesEnviadas: number,
        porcentajeConversion: number,
        porcentajeCancelacion: number,
        cotizaciones: Servicio[],
        pipcRiesgoBajo: Servicio[],
        pipcRiesgoMedio: Servicio[],
        promedioDesarrolloServicioPorOperativo: number,
        pipcEnDesarrollo: Servicio[],
        pipcIngresados: Servicio[],
        pipcLiberados: Servicio[],
        pipcObservaciones: Servicio[],
        pipcAtrasados: Servicio[],
        evaluacionesSatisfaccionSuperior95: Servicio[],
        evaluacionesSatisfaccionRealizadas: Servicio[],
        evaluacionesSatisfaccionPendientes: Servicio[],
        evaluacionesSatisfaccionSuperior70: Servicio[],
        evaluacionesSatisfaccionMenor70: Servicio[],
    }
    >({
        cotizaciones: [],
        cotizacionesDeclinadas: 0,
        cotizacionesProceso: 0,
        cotizacionesEnviadas: 0,
        porcentajeCancelacion: 0,
        porcentajeConversion: 0,
        ventasConcretadas: 0,
        pipcRiesgoBajo: [],
        pipcRiesgoMedio: [],
        promedioDesarrolloServicioPorOperativo: 0,
        pipcEnDesarrollo: [],
        pipcIngresados: [],
        pipcLiberados: [],
        pipcObservaciones: [],
        pipcAtrasados: [],
        evaluacionesSatisfaccionSuperior95: [],
        evaluacionesSatisfaccionRealizadas: [],
        evaluacionesSatisfaccionPendientes: [],
        evaluacionesSatisfaccionSuperior70: [],
        evaluacionesSatisfaccionMenor70: [],
    });

    const mostrarMensajeError = (mensaje: string) => {
        setError(true);
        setMessage(mensaje);
        setShowMessage(true);
    }

    function label(value: any) {
        if (value === null) {
            return <span>-</span>
        } else {
            return <span>{value}</span>
        }
    }

    function porcentajeText(value: number): string {
        if (value)
            return `${Math.trunc(value)}%`
        return '---'
    }

    function porcentajeLabel(value: any) {
        if (value === null) {
            return <span>-</span>
        } else {
            return <span>{value}%</span>
        }
    }


    const cargarEstadisticas = (year: number, force: boolean = false, grupo: Grupo | null = null) => {
        if (anioSeleccionado !== year || (grupoSeleccionado != grupo) || force) {
            setAnioSeleccionado(year);
            setGrupoSeleccionado(grupo);
            /*getEstadisticasByAnio(year, (grupo ? grupo.id : null)).then(data => {
                setEstadisticaAnio(data);
                cargarCotizacionesByMes(year, mesSeleccionado, force, grupo);
            }).catch(err => {
                mostrarMensajeError('Ha ocurrido un error al obtener las estadísticas');
            })*/
        }
    }

    const cargarCotizacionesByMes = (anio: number, mes: number, force: boolean = false, grupo: Grupo | null = null) => {
        if (anioSeleccionado !== anio || mes !== mesSeleccionado || grupo !== grupoSeleccionado || force) {
            setCargandoCotizacionesByMes(true);
            setNombreMesSeleccionado(meses[mes]);
            setMesSeleccionado(mes);
            getCotizacionesByMes(anio, mes, (grupo ? grupo.id : null)).then(data => {
                if (!data.evaluacionesSatisfaccionPendientes) {
                    data.evaluacionesSatisfaccionPendientes = [];
                }
                if (!data.pipcLiberados) {
                    data.pipcLiberados = [];
                }
                setEstadisticaMes(data);

            }).catch(err => mostrarMensajeError('Ha ocurrido un error al obtener las estadísticas')).finally(
                () => {
                    setCargandoCotizacionesByMes(false);
                }
            )
        }
    }

    useEffect(() => {
        setGrupoSeleccionado(null);
        if (isAdmin()) {
            listGroups().then(data => {
                setGrupos(data);
            }).catch(err=>console.log(err));
        }else if (isVentas()){
            getGrupoAtencion().then(data => {
                setGrupoAtencionUsuario(data);
            }).catch(err=>console.log(err));
        }
    }, []);

    const tiempoPromedioPipcRiesgoBajo = (pipcRiesgoBajo: Servicio[]) => {
        let label = ''
        if (pipcRiesgoBajo && pipcRiesgoBajo.length > 0) {
            let sum = pipcRiesgoBajo.reduce(function (a: number, b: Servicio) {
                return a + (b.primeraEtapaOperativa ? b.primeraEtapaOperativa : 0)
            }, 0);
            label = `${sum / pipcRiesgoBajo.length} días`;

        } else {
            label = '---'
        }
        return <IndicadorItem titulo={'Tiempo promedio de desarrollo de PIPC Riesgo Bajo'}
                              valor={label}/>
    }
    const tiempoPromedioPipcRiesgoMedio = (pipcRiesgoMedio: Servicio[]) => {
        let label = ''
        if (pipcRiesgoMedio && pipcRiesgoMedio.length > 0) {
            let sum = pipcRiesgoMedio.reduce(function (a: number, b: Servicio) {
                return a + (b.primeraEtapaOperativa ? b.primeraEtapaOperativa : 0)
            }, 0);
            label = `${sum / pipcRiesgoMedio.length} días`;
        } else {
            label = '---'
        }
        return <IndicadorItem titulo={'Tiempo promedio de desarrollo de PIPC Riesgo Medio'}
                              valor={label}/>
    }


    // @ts-ignore
    return (
        <>
            <LoadingSpinner show={loading}/>
            <Container style={{marginBottom: '80px'}}>
                <div className='d-flex justify-content-start'
                     style={{width: '100%', marginTop: "10px", marginBottom: "10px"}}>
                    <div style={{marginTop: '12px'}}>
                        <span className='fw-bold' style={{color: '#3a3939'}}>Año: </span>
                    </div>
                    <div style={{marginLeft: '5px', marginTop: '10px'}}>
                        <DropdownButton id="dropdownAnio" size='sm' variant='outline-dark'
                                        title={`${anioSeleccionado}`}>
                            <Dropdown.Item href="#"
                                           onClick={() => setAnioSeleccionado(anioActual)}>{anioActual}</Dropdown.Item>
                            <Dropdown.Item href="#"
                                           onClick={() => setAnioSeleccionado(anioActual - 1)}>{anioActual - 1}</Dropdown.Item>
                            <Dropdown.Item href="#"
                                           onClick={() => setAnioSeleccionado(anioActual - 2)}>{anioActual - 2}</Dropdown.Item>
                            <Dropdown.Item href="#"
                                           onClick={() => setAnioSeleccionado(anioActual - 3)}>{anioActual - 3}</Dropdown.Item>
                            <Dropdown.Item href="#"
                                           onClick={() => setAnioSeleccionado(anioActual - 4)}>{anioActual - 4}</Dropdown.Item>
                            <Dropdown.Item href="#"
                                           onClick={() => setAnioSeleccionado(anioActual - 5)}>{anioActual - 5}</Dropdown.Item>
                        </DropdownButton>
                    </div>
                    {
                        isAdmin() &&
                        <>
                            <div style={{marginLeft: '10px', marginTop: '12px'}}>
                                <span className='fw-bold' style={{color: '#3a3939'}}>Grupo: </span>
                            </div>
                            <div style={{marginLeft: '5px', marginTop: '10px'}}>
                                <DropdownButton size='sm' variant='outline-dark'
                                                title={grupoSeleccionado ? grupoSeleccionado.nombre : 'Todos los grupos'}>
                                    <Dropdown.Item href="#" onClick={() => setGrupoSeleccionado(null)}>Todos los grupos</Dropdown.Item>
                                    {
                                        grupos?.map((grupo: Grupo) => {
                                            return <Dropdown.Item href="#"
                                                                  onClick={() => setGrupoSeleccionado(grupo)}>{grupo.nombre}</Dropdown.Item>
                                        })
                                    }
                                </DropdownButton>
                            </div>
                        </>

                    }
                    <div style={{marginLeft: '20px', marginTop: '10px'}}>
                        <DownloadButton url={`/api/servicios/estadisticas/download`}
                                        params={[
                                            {key: 'anio', value: anioSeleccionado},
                                            {key: 'grupoId', value: grupoSeleccionado?.id},
                                        ]}
                                        fileName='Estadisticas.xlsx'
                                        text='Descargar excel'
                                        textDownloading='Descargando ...'
                                        icon='bi-file-bar-graph'/>
                    </div>
                    <div style={{marginLeft: '20px', marginTop: '10px'}}>
                        <Button size='sm' variant='outline-dark' onClick={() => {

                        }}>
                            <i className="bi bi-arrow-repeat"/> Actualizar
                        </Button>
                    </div>
                </div>
                <hr/>
                {
                    isVentas() &&
                    <div>
                        <span className='fw-bold fs-3'>Grupo de atención:</span> <span
                        className='fs-3'>{grupoAtencionUsuario?grupoAtencionUsuario.nombre:''}</span>
                    </div>
                }
                <DashboardAdministrativo anio={anioSeleccionado} grupo={grupoSeleccionado}/>
                <Container hidden={true}>
                    <div className='d-flex justify-content-start'
                         style={{width: '100%', marginTop: "10px", marginBottom: "10px"}}>
                        <div><span className="fs-3 fw-bold" style={{color: '#3a3939'}}>Detalles del mes de </span>
                        </div>
                        <div style={{marginLeft: '20px', marginTop: '10px', marginRight: '20px'}}>
                            <DropdownButton size='sm' variant='outline-dark' title={nombreMesSeleccionado}>
                                <Dropdown.Item onClick={() => {
                                    cargarCotizacionesByMes(anioSeleccionado, 0);
                                }}>Enero</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 1);
                                }}>Febrero</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 2);
                                }}>Marzo</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 3);
                                }}>Abril</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 4);
                                }}>Mayo</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 5);
                                }}>Junio</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 6);
                                }}>Julio</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 7);
                                }}>Agosto</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 8);
                                }}>Septiembre</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 9);
                                }}>Octubre</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 10);
                                }}>Noviembre</Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    cargarCotizacionesByMes(anioSeleccionado, 11);
                                }}>Diciembre</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div>
                            <span className="fs-3 fw-bold" style={{color: '#3a3939'}}>{anioSeleccionado}</span>
                        </div>
                    </div>

                    <Tabs defaultActiveKey='ventas' id='estadisticasTab'
                          className='mb-3' style={{marginTop: '10px'}}>
                        <Tab eventKey='ventas' title='Actividades'>

                            <div style={{marginTop: '20px'}}>
                                <Row>
                                    <Col>
                                        <IndicadorItem titulo={'Ventas concretadas'}
                                                       valor={estadisticaMes.ventasConcretadas}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'Cotizaciones declinadas'}
                                                       valor={estadisticaMes.cotizacionesDeclinadas}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'Cotizaciones en proceso'}
                                                       valor={estadisticaMes.cotizacionesProceso}/>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{marginTop: '20px', borderBottom: '1px solid #c1c1c1'}}>
                                <Row>
                                    <Col>
                                        <IndicadorItem titulo={'Cotizaciones enviadas'}
                                                       valor={estadisticaMes.cotizacionesEnviadas}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'Porcentaje de conversión'}
                                                       valor={porcentajeText(estadisticaMes.porcentajeConversion)}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'Porcentaje de cancelación'}
                                                       valor={porcentajeText(estadisticaMes.porcentajeCancelacion)}/>
                                    </Col>
                                </Row>
                            </div>


                            <div style={{marginTop: '20px'}}>

                                <Loading cargando={cargandoCotizacionesByMes}/>
                                {
                                    (estadisticaMes.cotizaciones && estadisticaMes.cotizaciones.length > 0) ?
                                        <>
                                            <ListGroup>
                                                <ListGroup.Item className='fw-bold' key={'headerDetalles'}
                                                                style={{backgroundColor: '#3a3939', color: 'white'}}>
                                                    <Row>
                                                        <Col sm='6'>Folio</Col>
                                                        <Col>Tipo</Col>
                                                        <Col>Status</Col>
                                                        {
                                                            isAdmin() &&
                                                            <Col>Grupo</Col>
                                                        }
                                                    </Row>
                                                </ListGroup.Item>
                                                {
                                                    estadisticaMes.cotizaciones.map((cotizacion: Servicio) => {
                                                        return <ListGroup.Item key={`${cotizacion.id}`}>
                                                            <Row>
                                                                <Col sm='6'><label
                                                                    className='fw-bold'>{cotizacion.folio}</label></Col>
                                                                <Col>{getTipoLabel(cotizacion.tipo)}</Col>
                                                                <Col>
                                                                    {getStatusLabel(cotizacion.status)}
                                                                    {
                                                                        cotizacion.status === 'finalizado' &&
                                                                        <div style={{fontSize: '0.7rem'}}>
                                                                            <label>Primera etapa operativa: <span
                                                                                className='fw-bold'>{cotizacion.primeraEtapaOperativa}</span></label><br/>
                                                                            <label>Segunda etapa operativa: <span
                                                                                className='fw-bold'>{cotizacion.segundaEtapaOperativa}</span></label>
                                                                        </div>
                                                                    }

                                                                </Col>
                                                                {
                                                                    isAdmin() &&
                                                                    <Col>
                                                                        {cotizacion.grupo?.nombre}
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </ListGroup.Item>
                                                    })
                                                }
                                            </ListGroup>
                                        </> :
                                        <div className='d-flex justify-content-center' style={{marginTop: '40px'}}><span
                                            className='fs-3 fw-bold'
                                            style={{color: '#8f8888'}}>No se encontraron resultados</span>
                                        </div>
                                }

                            </div>

                        </Tab>
                        <Tab eventKey='tiempoDesarrolloPIPC' title='Tiempo de desarrollo de PIPC'>
                            <div style={{marginTop: '20px', borderBottom: '1px solid #c1c1c1'}}>
                                <Row>
                                    <Col>{tiempoPromedioPipcRiesgoBajo(estadisticaMes.pipcRiesgoBajo)}</Col>
                                    <Col>{tiempoPromedioPipcRiesgoMedio(estadisticaMes.pipcRiesgoMedio)}</Col>
                                    <Col>
                                        <IndicadorItem
                                            titulo={'Tiempo promedio de desarrollo del servicio por Operativo '}
                                            valor={`${estadisticaMes.promedioDesarrolloServicioPorOperativo} días`}/>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{marginTop: '20px'}}>
                                <ListGroup>
                                    <ListGroup.Item className='fw-bold' key={'headerDetalles'}
                                                    style={{backgroundColor: '#3a3939', color: 'white'}}>
                                        <Row>
                                            <Col sm='6'>Folio</Col>
                                            <Col sm='3'>Nivel de riesgo</Col>
                                            <Col sm='3'>Tiempo de desarrollo de PIPC</Col>
                                        </Row>
                                    </ListGroup.Item>
                                    {
                                        estadisticaMes.pipcRiesgoBajo?.map((s: Servicio, index: number) => {
                                            return <ListGroup.Item key={`${index}`}>
                                                <Row>
                                                    <Col sm='6' className="fw-bold">
                                                        {s.folio}
                                                    </Col>
                                                    <Col>
                                                        Bajo
                                                    </Col>
                                                    <Col sm='3' className="fw-bold">
                                                        {s.primeraEtapaOperativa} días
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })
                                    }
                                    {
                                        estadisticaMes.pipcRiesgoMedio?.map((s: Servicio, index: number) => {
                                            return <ListGroup.Item key={`${index}`}>
                                                <Row>
                                                    <Col sm='6' className="fw-bold">
                                                        {s.folio}
                                                    </Col>
                                                    <Col>
                                                        Medio
                                                    </Col>
                                                    <Col sm='3' className="fw-bold">
                                                        {s.primeraEtapaOperativa} días
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })
                                    }
                                </ListGroup>
                            </div>
                        </Tab>
                        <Tab eventKey='desarrolloPIPC' title='Desarrollo de PIPC'>
                            <div style={{marginTop: '20px', borderBottom: '1px solid #c1c1c1'}}>
                                <Row>
                                    <Col>
                                        <IndicadorItem titulo={'PIPC en desarrollo del mes'}
                                                       valor={`${estadisticaMes.pipcEnDesarrollo.length}`}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'PIPC ingresados en el mes'}
                                                       valor={`${estadisticaMes.pipcIngresados.length}`}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'PIPC liberados en el mes'}
                                                       valor={`${estadisticaMes.pipcLiberados.length}`}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'PIPC con observaciones'}
                                                       valor={`${estadisticaMes.pipcObservaciones.length}`}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'PIPC atrasados del mes'}
                                                       valor={`${estadisticaMes.pipcAtrasados.length}`}/>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{marginTop: '20px'}}>
                                <ListGroup>
                                    <ListGroup.Item className='fw-bold' key={'headerDetalles'}
                                                    style={{backgroundColor: '#3a3939', color: 'white'}}>
                                        <Row>
                                            <Col sm='6'>Folio</Col>
                                            <Col>Status</Col>
                                        </Row>
                                    </ListGroup.Item>
                                    {
                                        estadisticaMes.pipcEnDesarrollo?.map((s: Servicio, index: number) => {
                                            return <ListGroup.Item key={`${index}`}>
                                                <Row>
                                                    <Col sm='6' className="fw-bold">
                                                        {s.folio}
                                                    </Col>
                                                    <Col className="fw-bold">
                                                        En desarrollo
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })

                                    }
                                    {
                                        estadisticaMes.pipcIngresados?.map((s: Servicio, index: number) => {
                                            return <ListGroup.Item key={`${index}`}>
                                                <Row>
                                                    <Col sm='6' className="fw-bold">
                                                        {s.folio}
                                                    </Col>
                                                    <Col className="fw-bold">
                                                        Ingresado
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })

                                    }
                                    {
                                        estadisticaMes.pipcLiberados?.map((s: Servicio, index: number) => {
                                            return <ListGroup.Item key={`${index}`}>
                                                <Row>
                                                    <Col sm='6' className="fw-bold">
                                                        {s.folio}
                                                    </Col>
                                                    <Col className="fw-bold">
                                                        Liberado
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })

                                    }
                                    {
                                        estadisticaMes.pipcObservaciones?.map((s: Servicio, index: number) => {
                                            return <ListGroup.Item key={`${index}`}>
                                                <Row>
                                                    <Col sm='6' className="fw-bold">
                                                        {s.folio}
                                                    </Col>
                                                    <Col className="fw-bold">
                                                        Con observaciones
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })

                                    }
                                    {
                                        estadisticaMes.pipcAtrasados?.map((s: Servicio, index: number) => {
                                            return <ListGroup.Item key={`${index}`}>
                                                <Row>
                                                    <Col sm='6' className="fw-bold">
                                                        {s.folio}
                                                    </Col>
                                                    <Col className="fw-bold">
                                                        Atrasado
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })

                                    }
                                </ListGroup>
                            </div>

                        </Tab>
                        <Tab eventKey='evaluaciones' title='Evaluaciones de satisfacción'>
                            <div style={{marginTop: '40px', borderBottom: '1px solid #c1c1c1'}}>
                                <Row>
                                    <Col>
                                        <IndicadorItem
                                            titulo={'Evaluaciones con calificación superior al 70%'}
                                            valor={`${porcentajeText((estadisticaMes.evaluacionesSatisfaccionSuperior70.length / estadisticaMes.evaluacionesSatisfaccionRealizadas.length) * 100.0)}`}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'Evaluaciones de satisfacción realizadas'}
                                                       valor={`${estadisticaMes.evaluacionesSatisfaccionRealizadas.length}`}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'Evaluaciones de satisfacción pendientes'}
                                                       valor={`${estadisticaMes.evaluacionesSatisfaccionPendientes.length}`}/>
                                    </Col>
                                    <Col>
                                        <IndicadorItem titulo={'Evaluaciones con calificación superior al 95%'}
                                                       valor={`${porcentajeText((estadisticaMes.evaluacionesSatisfaccionSuperior95.length / estadisticaMes.evaluacionesSatisfaccionRealizadas.length) * 100.0)}`}/>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{marginTop: '20px'}}>
                                <ListGroup>
                                    <ListGroup.Item className='fw-bold' key={'headerDetalles'}
                                                    style={{backgroundColor: '#3a3939', color: 'white'}}>
                                        <Row>
                                            <Col sm='6'>Folio</Col>
                                            <Col sm='3'>Status</Col>
                                            <Col>Calificación</Col>
                                        </Row>
                                    </ListGroup.Item>
                                    {
                                        estadisticaMes.evaluacionesSatisfaccionPendientes.map((servicio: Servicio) => {
                                            return <ListGroup.Item key={`${servicio.id}`}>
                                                <Row>
                                                    <Col sm='6'><label
                                                        className='fw-bold'>{servicio.folio}</label></Col>
                                                    <Col>Pendiente</Col>
                                                    <Col></Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })
                                    }
                                    {
                                        estadisticaMes.evaluacionesSatisfaccionSuperior70.map((servicio: Servicio) => {
                                            return <ListGroup.Item key={`${servicio.id}`}>
                                                <Row>
                                                    <Col sm='6'><label className='fw-bold'
                                                                       style={{fontSize: '0.8em'}}>{servicio.folio}</label></Col>
                                                    <Col></Col>
                                                    <Col>{servicio.encuestaSatisfaccion?.calificacion}</Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })
                                    }
                                    {
                                        estadisticaMes.evaluacionesSatisfaccionMenor70.map((servicio: Servicio) => {
                                            return <ListGroup.Item key={`${servicio.id}`}>
                                                <Row>
                                                    <Col sm='6'><label className='fw-bold'
                                                                       style={{fontSize: '0.8em'}}>{servicio.folio}</label></Col>
                                                    <Col></Col>
                                                    <Col>{servicio.encuestaSatisfaccion?.calificacion}</Col>
                                                </Row>
                                            </ListGroup.Item>
                                        })
                                    }
                                </ListGroup>
                            </div>
                        </Tab>
                    </Tabs>
                </Container>

            </Container>

            <ModalMessage show={showMessage}
                          close={() => setShowMessage(false)}
                          error={error}
                          message={message}/>

        </>
    )
}
export default Dashboard;