import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {
    generarFolioCliente,
    listGroups,
    registrarServicioPrueba,
    updateCotizacion,
    uploadTmpFile,
} from "../../services/conem-services";
import CatalogoServicioPanel from "./CatalogoServicioPanel";
import {Archivo, CatalogoServicio, Grupo, Servicio, Subservicio, NamedItem} from "../../types/servicio";
import ModalMessage from "../common/ModalMessage";
import LoadingSpinner from "../common/LoadingSpinner";
import {
    dateToSimpleFormat, FILE_MAX_SIZE,
    formatoNumeroFolio,
    isAdmin,
    isCorreoValido,
    isTelefonoValido,
    FOLIO_PREFIX,
    OK,
    toDate,
    truncarTexto
} from "../../utils";
import CatalogoServicioModal from "../catalogo-servicio/CatalogoServicioModal";
import cloneDeep from "lodash.clonedeep";
import {useFormInput} from "./useFormInput";
import DatePicker from "react-datepicker";
import DiasSemana from "./DiasSemana";
import FileChooser from "../documento/FileChooser";
import {toInteger} from "lodash";
import DeleteButton from "../common/DeleteButton";
import {useSecuencias} from "../../hooks/useSecuencias";
import FileSelector from "../FileSelector/FileSelector";

export default function FormEdicionCotizacion(props: {
    cotizacion: Servicio,
    onClose: () => void,
    onUpdate: (cotizacion: Servicio) => void
}) {

    const [constancias, setConstancias] = useState<Archivo[]>([]);
    const [documentos, setDocumentos] = useState<Archivo[]>([]);
    const [documentoCotizacionFile, setDocumentoCotizacionFile] = useState<any>(null);


    const [cotizacion, setCotizacion] = useState<Servicio>(props.cotizacion);
    const [secuenciaFolioActual, setSecuenciaFolioActual] = useState<number>(1);
    const secuencias = useSecuencias();

    const [tipoCliente, setTipoCliente] = useState<string>('nuevo');
    const [nivelRiesgo, setNivelRiesgo] = useState('bajo');

    const [requiereFactura, setRequiereFactura] = useState<boolean>(false);
    const [grupos, setGrupos] = useState<Grupo[] | null>(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState<Grupo | null>(null);
    const [vistoBuenoAniosAnteriores, setVistoBuenoAniosAnteriores] = useState<boolean>(false);
    const [vigenciaVistoBueno, setVigenciaVistoBueno] = useState<Date | null>(null);
    const [diasLaborales, setDiasLaborales] = useState<NamedItem[]>([
        {
            name: 'Domingo', enabled: false
        },
        {
            name: 'Lunes', enabled: true
        },
        {
            name: 'Martes', enabled: true
        },
        {
            name: 'Miércoles', enabled: true
        },
        {
            name: 'Jueves', enabled: true
        },
        {
            name: 'Viernes', enabled: true
        },
        {
            name: 'Sábado', enabled: false
        },
    ]);
    const changeSelectionDay = (index: number, value: boolean) => {
        setDiasLaborales(
            diasLaborales.map((day: NamedItem, i: number) => {
                if (i === index) {
                    day.enabled = value;
                }
                return day;
            })
        )
    }

    const hiddenFileInput = useRef(null);

    const [catalogoPrefijoFolio, setCatalogoPrefijoFolio] = useState<string | null>(null);
    const [documentoCotizacion, setDocumentoCotizacion] = useState<Archivo | null>(null);

    const [constanciaFiscal, setConstanciaFiscal] = useState<Archivo | null>(null);


    const [loading, setLoading] = useState(false)

    const [catalogoServicio, setCatalogoServicio] = useState<CatalogoServicio>(
        {
            prefijoFolio: props.cotizacion.folio.split('-')[1],
            actividades: props.cotizacion.actividades,
            descripcion: props.cotizacion.descripcion,
            descuento: props.cotizacion.descuento,
            documentos: props.cotizacion.documentos,
            errores: null,
            id: props.cotizacion.id,
            iva: props.cotizacion.iva,
            plantillaDescargable: props.cotizacion.plantillaDocumentoCotizacion,
            precio: props.cotizacion.precio,
            subservicios: props.cotizacion.subservicios,
            subtotal: props.cotizacion.subtotal

        }
    );

    const [showCatalogoServicioPanel, setShowCatalogoServicioPanel] = useState<boolean>(false);

    const [msgBtnCreateServiceProposal, setMsgBtnCreateServiceProposal] = useState("Actualizar cotización");
    const [disableBtnCreateServiceProposal, setDisableBtnCreateServiceProposal] = useState(false);


    useEffect(() => {

        secuencias.getCurrentSecuencia(props.cotizacion.idFolio).then(value => {
            setSecuenciaFolioActual(value);
        });

        const copyCotizacion = cloneDeep(props.cotizacion);
        setCotizacion(copyCotizacion);

        setNivelRiesgo(copyCotizacion.cliente.detalle.nivelRiesgo);
        setTipoCliente(copyCotizacion.clienteNuevo ? 'nuevo' : 'renovacion');

        setRequiereFactura(copyCotizacion.requiereFactura);
        if (copyCotizacion.constanciaSituacionFiscal) {
            setConstanciaFiscal(copyCotizacion.constanciaSituacionFiscal);
        }
        if (copyCotizacion.cuentaVistoBuenoPCAnteriores) {
            setVistoBuenoAniosAnteriores(copyCotizacion.cuentaVistoBuenoPCAnteriores)
        }
        if (copyCotizacion.vigenciaVistoBueno) {
            setVigenciaVistoBueno(toDate(copyCotizacion.vigenciaVistoBueno));
        }

        if (copyCotizacion.diasLaborales) {
            setDiasLaborales(copyCotizacion.diasLaborales);
        }

        if (copyCotizacion.documentoCotizacion) {
            setDocumentoCotizacion(copyCotizacion.documentoCotizacion)
        }

        listGroups().then(data => {
            setGrupos(data);
        }).catch(error => {
            console.log(error)
        })

        setGrupoSeleccionado(copyCotizacion.grupo);

        setCatalogoServicio({
            prefijoFolio: copyCotizacion.folio.split('-')[1],
            actividades: copyCotizacion.actividades,
            descripcion: copyCotizacion.descripcion,
            descuento: copyCotizacion.descuento,
            documentos: copyCotizacion.documentos,
            errores: null,
            id: copyCotizacion.id,
            iva: copyCotizacion.iva,
            plantillaDescargable: copyCotizacion.plantillaDocumentoCotizacion,
            precio: copyCotizacion.precio,
            subservicios: copyCotizacion.subservicios,
            subtotal: copyCotizacion.subtotal,
        })

        setCatalogoPrefijoFolio(copyCotizacion.folio.split('-')[1]);

    }, [props.cotizacion]);


    const downloadFile = (archivo: Archivo) => {
        if (archivo.url) {

        } else {
            //if archivo is a local file

        }
    }


    const clickSelectFiles = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click();
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files.item(0);
            if (file && (file.size <= 0 || file.size >= FILE_MAX_SIZE)) {
                //showError("El tamaño mínimo de archivo es 1 byte y menor a 50 MB");
                return;
            }

            if (file) {

                setDocumentoCotizacion({
                    dropboxLink: 'undefined',
                    fechaSubido: "",
                    folder: 'undefined',
                    id: 'undefined',
                    seleccionado: false,
                    subidoPor: 'undefined',
                    tamanio: 0,
                    url: "",
                    nombre: file.name
                })


                const formData = new FormData();
                formData.set("file", e.target.files[0]);

                setDocumentoCotizacion(null);
                uploadTmpFile(formData).then(
                    resp => {
                        if (resp.statusCode === OK) {
                            setDocumentoCotizacion(resp.data);
                        } else {
                            //showError(resp.message);
                        }
                    }
                ).catch(err => {
                    //showError("Lo sentimos ocurrió un error al intentar subir los archivos, por favor inténtelo más tarde");
                })
            }
            // @ts-ignore
            e.target.value = null;
        }
    };

    function isFormValid() {
        let valid = true;
        valid = (
            correo.error === null &&
            nombreContacto.error === null &&
            telefonoContacto.error === null &&
            direccionExacta.error === null &&
            giroComercial.error === null &&
            metrosConstruccion.error === null &&
            sustanciasQuimicosPeligrosos.error === null &&
            gasCapacidadAlmacenamiento.error === null &&
            razonSocial.error === null
        )
        return valid;
    }

    const newInstanciaCotizacion = () => {
        const updatedCotizacion = {...cotizacion}
        updatedCotizacion.folio = FOLIO_PREFIX + '-' + catalogoPrefijoFolio + '-' + claveFolio.value + '-' + formatoNumeroFolio(secuenciaFolioActual);
        updatedCotizacion.cliente.correo = correo.value;
        updatedCotizacion.cliente.nombreCompleto = nombreContacto.value;
        updatedCotizacion.cliente.telefono = telefonoContacto.value;
        updatedCotizacion.cliente.detalle.nombreComercial = nombreComercial.value;
        updatedCotizacion.cliente.detalle.direccion = direccionExacta.value;
        updatedCotizacion.cliente.detalle.giro = giroComercial.value;
        updatedCotizacion.cliente.detalle.metrosConstruccion = metrosConstruccion.value;
        updatedCotizacion.cliente.detalle.metrosTerreno = metrosTerreno.value;
        updatedCotizacion.cliente.detalle.numeroEmpleados = numeroEmpleados.value;
        updatedCotizacion.cliente.detalle.sustanciasQuimicasPeligrosas = sustanciasQuimicosPeligrosos.value;
        updatedCotizacion.cliente.detalle.gasCapacidadAlmacenamiento = gasCapacidadAlmacenamiento.value;
        updatedCotizacion.cliente.detalle.razonSocial = razonSocial.value;
        updatedCotizacion.cliente.detalle.domicilioFiscal = domicilioFiscal.value;
        updatedCotizacion.cliente.detalle.rfc = rfc.value;
        updatedCotizacion.cliente.detalle.nivelRiesgo = nivelRiesgo;
        updatedCotizacion.clienteNuevo = tipoCliente === 'nuevo';
        updatedCotizacion.requiereFactura = requiereFactura;
        updatedCotizacion.constanciaSituacionFiscal = requiereFactura ? constanciaFiscal : null;
        updatedCotizacion.cuentaVistoBuenoPCAnteriores = vistoBuenoAniosAnteriores;
        updatedCotizacion.vigenciaVistoBueno = vigenciaVistoBueno ? dateToSimpleFormat(vigenciaVistoBueno) : null;
        updatedCotizacion.diasLaborales = diasLaborales;
        updatedCotizacion.representanteLegalInmueble = representanteLegalInmueble.value;
        updatedCotizacion.nombrePersonalRecibeInspector = nombrePersonalRecibiraInspector.value;
        updatedCotizacion.nivelesConstruccion = nivelesConstruccion.value;
        updatedCotizacion.horarioLaboral = horarioLaboral.value;
        updatedCotizacion.grupo = grupoSeleccionado;


        updatedCotizacion.claveFolio = claveFolio.value;
        updatedCotizacion.idFolio = claveFolio.value.toUpperCase() + catalogoPrefijoFolio?.toUpperCase()
        updatedCotizacion.folioSecuencia = secuenciaFolioActual;
        updatedCotizacion.prefijoFolio = catalogoPrefijoFolio ? catalogoPrefijoFolio.toUpperCase() : '';

        return updatedCotizacion;
    }

    const resetSecuenciaFolio = (claveFolio: string) => {
        if (claveFolio) {
            secuencias.getCurrentSecuencia(`${claveFolio.toUpperCase()}${props.cotizacion.prefijoFolio.toUpperCase()}`).then(
                value => {
                    setSecuenciaFolioActual(value);
                }
            )
        } else {
            return 1;
        }
    }

    const actualizarCotizacion = async () => {

        if (isFormValid() && catalogoServicio && catalogoPrefijoFolio) {
            if (!documentoCotizacion) {
                mostrarMensajeError("Se requiere especificar el documento de cotización");
                return;
            }
            const updatedCotizacion = newInstanciaCotizacion();
            const documentos = cloneDeep(catalogoServicio.documentos);
            const subservicios = cloneDeep(catalogoServicio.subservicios);
            const sumaDescuentos = subservicios.reduce((accumulator: number, item: Subservicio) => {
                return accumulator + (item.precio * (item.porcentajeDescuento / 100));
            }, 0);
            const sumaPrecios = subservicios.reduce((accumulator: number, item: Subservicio) => {
                return accumulator + item.precio;
            }, 0);
            const iva = (sumaPrecios - sumaDescuentos) * 0.16;
            updatedCotizacion.descripcion = catalogoServicio.descripcion;
            updatedCotizacion.precio = sumaPrecios;
            updatedCotizacion.descuento = sumaDescuentos;
            updatedCotizacion.iva = iva;
            updatedCotizacion.subtotal = (sumaPrecios - sumaDescuentos);
            updatedCotizacion.total = (sumaPrecios - sumaDescuentos) + iva;
            updatedCotizacion.subservicios = subservicios;
            updatedCotizacion.documentos = documentos;
            updatedCotizacion.documentoCotizacion = documentoCotizacion;

            setMsgBtnCreateServiceProposal("Guardando...");
            setLoading(true);
            setDisableBtnCreateServiceProposal(true);
            updateCotizacion({
                cotizacion: updatedCotizacion,
            }).then((data) => {
                if (data.statusCode === OK) {
                    props.onUpdate(data.data);
                    props.onClose();
                } else {
                    mostrarMensajeError(data.message);
                }
            }).catch(err => {
                mostrarMensajeError('Lo sentimos ha ocurrido un error al intentar guardar la cotización');
            }).finally(() => {
                setMsgBtnCreateServiceProposal("Actualizar cotización");
                setLoading(false);
                setDisableBtnCreateServiceProposal(false);
            });
        } else {
            mostrarMensajeError('Por favor verifique y proporcione los campos requeridos');
        }
    }

    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const mostrarMensajeError = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }

    const correo = useFormInput(
        'email',
        props.cotizacion.cliente.correo,
        100,
        'El correo de contacto es requerido',
        false,
        true,
        false,
        isCorreoValido,
    );

    const nombreContacto = useFormInput(
        'text',
        props.cotizacion.cliente.nombreCompleto,
        200,
        'El nombre del contacto es requerido',
        false,
        true,
        false
    );
    const telefonoContacto = useFormInput(
        'text',
        props.cotizacion.cliente.telefono,
        10,
        'El número de teléfono de contacto es requerido',
        false,
        true,
        false,
        isTelefonoValido,
    );
    const claveFolio = useFormInput(
        'text',
        cotizacion.claveFolio,
        100,
        'El número de folio es requerido y debe ser válido',
        false,
        true,
        false,
        (value: string) => {
            if (!value.match(/[A-Z]+/)) {
                return false;
            } else {
                resetSecuenciaFolio(value);
                return true;
            }
        },
    );
    const nombreComercial = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.nombreComercial,
        200,
        'El nombre comercial es un campo requerido',
        false,
        true,
        false,
        null,
        (value: string) => {
            if (!claveFolio.value) {
                if (value) {
                    const newClaveFolio = truncarTexto(value.toUpperCase().replace(/[\s|\.|,|_|-]/g, ''), 20, false);
                    claveFolio.setValue(newClaveFolio)
                    resetSecuenciaFolio(newClaveFolio);
                }
            }
        }
    );
    const direccionExacta = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.direccion,
        400,
        'La dirección exacta es un campo requerido',
        false,
        true,
        false,
    );
    const giroComercial = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.giro,
        400,
        'El campo Giro Comercial es requerido',
        false,
        true,
        false,
    );
    const metrosConstruccion = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.metrosConstruccion,
        100,
        'El campo Metros de Construcción es requerido',
        false,
        true,
        false,
    );
    const metrosTerreno = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.metrosTerreno,
        100,
        null,
        true,
        false,
        false,
    );
    const numeroEmpleados = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.numeroEmpleados,
        100,
        null,
        true,
        false,
        false,
    );
    const sustanciasQuimicosPeligrosos = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.sustanciasQuimicasPeligrosas,
        300,
        'Indique NA en caso de no aplicar',
        false,
        true,
        false,
    );
    const gasCapacidadAlmacenamiento = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.gasCapacidadAlmacenamiento,
        300,
        'Indique NA en caso de no aplicar',
        false,
        true,
        false,
    );
    const razonSocial = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.razonSocial,
        100,
        'La Razón Social es un campo requerido',
        false,
        true,
        false,
    );
    const domicilioFiscal = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.domicilioFiscal,
        400,
        null,
        true,
        false,
        false,
    );
    const rfc = useFormInput(
        'text',
        props.cotizacion.cliente.detalle.rfc,
        30,
        null,
        true,
        false,
        false,
    );

    const representanteLegalInmueble = useFormInput(
        'text',
        props.cotizacion.representanteLegalInmueble,
        200,
        null,
        true,
        false,
        false,
    );
    const nombrePersonalRecibiraInspector = useFormInput(
        'text',
        props.cotizacion.nombrePersonalRecibeInspector,
        200,
        null,
        true,
        false,
        false,
    );
    const nivelesConstruccion = useFormInput(
        'text',
        props.cotizacion.nivelesConstruccion,
        50,
        null,
        true,
        false,
        false,
    );
    const horarioLaboral = useFormInput(
        'text',
        props.cotizacion.horarioLaboral,
        50,
        null,
        true,
        false,
        false,
    );

    const downloadPreviewDoc = async (catalogoServicio: CatalogoServicio) => {
        if (isFormValid()) {
            const folioTemporal = FOLIO_PREFIX + '-' + claveFolio.value + '-' + formatoNumeroFolio(secuenciaFolioActual);
            // @ts-ignore
            let updatedServicio: Servicio = {
                requiereFactura: requiereFactura,
                grupo: grupoSeleccionado,
                plantillaDocumentoCotizacion: catalogoServicio.plantillaDescargable,
                folio: folioTemporal,
                cliente: {
                    nombreCompleto: nombreContacto.value,
                    correo: correo.value,
                    telefono: telefonoContacto.value,
                    detalle: {
                        nombreComercial: nombreComercial.value,
                        giro: giroComercial.value,
                        metrosConstruccion: metrosConstruccion.value,
                        metrosTerreno: metrosTerreno.value,
                        numeroEmpleados: numeroEmpleados.value,
                        direccion: direccionExacta.value,
                        sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos.value,
                        gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento.value,
                        nivelRiesgo: nivelRiesgo,
                        razonSocial: razonSocial.value,
                        domicilioFiscal: domicilioFiscal.value,
                        rfc: rfc.value,
                    }
                },
                total: catalogoServicio.iva + catalogoServicio.subtotal,
                ...catalogoServicio
            }

            fetch(`/api/cotizaciones/${catalogoServicio.id}/documento/download`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedServicio),
                }).then(response => {
                if (response.ok) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `${folioTemporal}.docx`;
                        a.click();
                    });
                } else {
                    throw new Error('Ocurrió un error al intentar descargar el documento');
                }
            }).catch(err => alert(err));

        } else {
            mostrarMensajeError("Favor de verificar y especificar los parametros requeridos");
        }
    }

    return (
        <Container style={{marginTop: '20px', marginBottom: '20px'}}>
            <LoadingSpinner show={loading}></LoadingSpinner>

            <Container>

                <input id="file" type="file" ref={hiddenFileInput} accept='application/pdf'
                       style={{display: 'none'}}
                       onChange={handleFileChange}/>

                <Form className="row g-6" id="formGeneral">
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Correo del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...correo}/>
                        <Form.Control.Feedback type="invalid">{correo.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control {...nombreContacto}/>
                        <Form.Control.Feedback type="invalid">{nombreContacto.error}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Teléfono del Contacto</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...telefonoContacto}/>
                        <Form.Control.Feedback
                            type="invalid">{telefonoContacto.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Nombre Comercial</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...nombreComercial}></Form.Control>
                        <Form.Control.Feedback
                            type="invalid">{nombreComercial.error}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Folio</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>

                        <div>
                            <Row>
                                <Col sm='4'>
                                    <Form.Control type='text' disabled={true}
                                                  value={FOLIO_PREFIX + '-' + props.cotizacion.prefijoFolio}/>
                                </Col>
                                <Col>
                                    <Form.Control  {...claveFolio}/>
                                </Col>
                                <Col sm='4'>
                                    <Form.Control type='text' disabled={true}
                                                  value={formatoNumeroFolio(secuenciaFolioActual)}/>
                                    {
                                        secuenciaFolioActual > 0 && <span className='text-muted'
                                                                          style={{fontSize: '0.5rem'}}><span
                                            className='bi bi-info-circle'/> Folio actualizado para edición</span>
                                    }
                                </Col>
                            </Row>


                        </div>
                        <Form.Control.Feedback
                            type="invalid">{claveFolio.error}</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Dirección Exacta del Inmueble</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...direccionExacta}/>
                        <Form.Control.Feedback
                            type="invalid">{direccionExacta.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Giro Comercial</Form.Label>
                        <Form.Control  {...giroComercial}/>
                        <Form.Control.Feedback
                            type="invalid">{giroComercial.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Metros cuadrados de Construcción</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...metrosConstruccion}/>
                        <Form.Control.Feedback
                            type="invalid">{metrosConstruccion.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Metros cuadrados de Terreno</Form.Label>
                        <Form.Control  {...metrosTerreno}/>
                        <Form.Control.Feedback
                            type="invalid">{metrosTerreno.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>No. de empleados</Form.Label>
                        <Form.Control  {...numeroEmpleados}/>
                        <Form.Control.Feedback
                            type="invalid">{numeroEmpleados.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Sustancias o Químicos Peligrosos</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...sustanciasQuimicosPeligrosos}/>
                        <Form.Control.Feedback
                            type="invalid">{sustanciasQuimicosPeligrosos.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Gas, capacidad de almacenamiento</Form.Label>
                        <Form.Text className="text-muted"> (Requerido)</Form.Text>
                        <Form.Control  {...gasCapacidadAlmacenamiento}/>
                        <Form.Control.Feedback
                            type="invalid">{gasCapacidadAlmacenamiento.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Razón Social</Form.Label>
                        <Form.Text className="text-muted"> (Se requiere el nombre de la empresa ante
                            el
                            SAT)</Form.Text>
                        <Form.Control  {...razonSocial}/>
                        <Form.Control.Feedback
                            type="invalid">{razonSocial.error}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>Domicilio Fiscal</Form.Label>
                        <Form.Control  {...domicilioFiscal}/>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral"
                                style={{marginBottom: "20px"}}>
                        <Form.Label>RFC (Registro Federal de Contribuyente)</Form.Label>
                        <Form.Control  {...rfc}/>
                    </Form.Group>
                    <Form.Group></Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral">
                        <Form.Label>Nivel de riesgo</Form.Label>
                        <div className="mb-3">
                            <Form.Check
                                checked={nivelRiesgo === 'bajo'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setNivelRiesgo('bajo')
                                    }
                                }} inline label="Bajo" type='radio'
                            />
                            <Form.Check
                                checked={nivelRiesgo === 'medio'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setNivelRiesgo('medio')
                                    }
                                }} inline label="Medio" type='radio'
                            />
                            <Form.Check
                                checked={nivelRiesgo === 'alto'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setNivelRiesgo('alto')
                                    }
                                }} inline label="Alto" type='radio'
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="col-md-6" controlId="formGeneral">
                        <Form.Label>Tipo de cliente</Form.Label>
                        <div className="mb-3">
                            <Form.Check
                                checked={tipoCliente === 'nuevo'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setTipoCliente('nuevo')
                                    }
                                }} inline label="Nuevo" type='radio'
                            />
                            <Form.Check
                                checked={tipoCliente !== 'nuevo'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setTipoCliente('renovacion')
                                    }
                                }} inline label="Renovación" type='radio'
                            />
                        </div>
                    </Form.Group>
                    <Form className="row g-6" id="formDatosGeneralesOperativos">
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Representante Legal del Inmueble</Form.Label>
                            <Form.Control  {...representanteLegalInmueble}/>
                        </Form.Group>
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Nombre del personal que recibirá al inspector</Form.Label>
                            <Form.Control  {...nombrePersonalRecibiraInspector}/>
                        </Form.Group>
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Niveles de construcción</Form.Label>
                            <Form.Control  {...nivelesConstruccion}/>
                        </Form.Group>
                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Horario laboral</Form.Label>
                            <Form.Control  {...horarioLaboral}/>
                        </Form.Group>


                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>Días laborales</Form.Label>
                            <div>
                                <DiasSemana workingDays={diasLaborales} disabled={false}
                                            change={changeSelectionDay}/>
                            </div>
                        </Form.Group>

                        <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                    style={{marginBottom: "20px"}}>
                            <Form.Label>¿Cuentan con visto bueno de protección civil de años
                                anteriores?</Form.Label>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           id='requiereFactura'
                                           checked={vistoBuenoAniosAnteriores}
                                           onChange={(e) => setVistoBuenoAniosAnteriores(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="requiereFactura">
                                        Sí
                                    </label>
                                </div>
                                {
                                    vistoBuenoAniosAnteriores &&
                                    <div>
                                        Vigencia:
                                        <DatePicker isClearable locale="es"
                                                    dateFormat='dd/MM/yyyy'
                                                    selected={vigenciaVistoBueno}
                                                    onChange={(date, event) => {
                                                        setVigenciaVistoBueno(date);
                                                    }}/>
                                    </div>
                                }
                            </div>

                        </Form.Group>
                    </Form>
                </Form>

                <hr/>
                <div style={{marginTop: "10px"}}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                               id='requiereFactura'
                               checked={requiereFactura}
                               onChange={(e) => setRequiereFactura(e.target.checked)}/>
                        <label className="form-check-label" htmlFor="requiereFactura">
                            ¿Requiere factura?
                        </label>
                        {
                            /*
                            requiereFactura && <FileChooser archivo={constanciaFiscal} disabled={false}
                                                            buttonUploadLabel={`${constanciaFiscal === null ? 'Agregar' : 'Actualizar'} constancia de situación fiscal`}
                                                            updatedFile={(e: Archivo) => setConstanciaFiscal(e)}/>
                            */
                        }
                        {
                            requiereFactura &&
                            <FileSelector
                                multiple={false}
                                text={'Agregar documento de cotización'}
                                archivos={documentos}
                                onSelect={(file: any) => {
                                    setDocumentoCotizacionFile(file);
                                    setDocumentos([{
                                        id: null,
                                        subidoPor: null,
                                        folder: null,
                                        dropboxLink: null,
                                        nombre: file.name,
                                        tamanio: file.size,
                                        fechaSubido: '',
                                    }])
                                }}
                                delete={() => {

                                    setDocumentoCotizacionFile(null);
                                    return new Promise(resolve => resolve(true));

                                }}/>
                        }
                    </div>
                </div>

                {
                    isAdmin() &&
                    <><Form.Label>Grupo de atención al que se envía esta cotización</Form.Label>
                        <div className="mb-3">
                            {
                                grupos?.map((g: Grupo) => {
                                    return <Form.Check
                                        checked={grupoSeleccionado?.id === g.id}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setGrupoSeleccionado(g);
                                            }
                                        }} inline label={g.nombre} type='radio'
                                    />
                                })
                            }
                        </div>
                    </>

                }


                {
                    cotizacion &&
                    <Container className="d-flex flex-row justify-content-center"
                               style={{marginTop: "30px", marginBottom: '30px'}}>
                        <CatalogoServicioPanel catalogoServicio={catalogoServicio}
                                               seleccionado={true}
                                               seleccionar={(e: CatalogoServicio) => {
                                               }}
                                               editar={(e: CatalogoServicio) => {
                                                   setShowCatalogoServicioPanel(true);
                                               }}
                                               downloadPreview={() => downloadPreviewDoc(catalogoServicio)}
                        />
                    </Container>
                }


                {
                    documentoCotizacion === null &&
                    <div className='rounded d-flex align-items-center justify-content-center'
                         style={{
                             border: '1px dashed gray',
                             height: '80px',
                             backgroundColor: '#fafafa'
                         }}>
                        <label className='fw-bold fs-5 folderName' onClick={clickSelectFiles}
                               style={{color: 'gray'}}>Agregar documento de cotización</label>
                    </div>
                }
                {
                    documentoCotizacion &&
                    <div className='rounded d-flex align-items-center d-inline-flex'
                         style={{
                             width: '100%',
                             border: '1px solid gray',
                             height: '80px',
                             backgroundColor: '#fafafa'
                         }}>

                        <div style={{marginLeft: '30px', width: '93%'}}>
                            <span style={{color: '#303030'}} className='fs-3 bi bi-file-earmark'/>
                            <span className='folderName fw-bold fs-5'
                                  style={{color: 'gray'}}>{documentoCotizacion.nombre}</span>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <DeleteButton onClick={() => {
                                setDocumentoCotizacion(null);
                            }}/>
                        </div>
                    </div>
                }


            </Container>
            <Container className="d-flex flex-row justify-content-center"
                       style={{marginTop: "30px"}}>
                <Button style={{borderRadius: "8px", padding: "10px", width: "260px"}}
                        variant="danger"
                        size='sm'
                        disabled={disableBtnCreateServiceProposal}
                        onClick={props.onClose}>
                    <span className='fw-bold fs-6'>
                    Cancelar
                        </span>
                </Button>
                <Button style={{borderRadius: "8px", padding: "10px", width: "260px"}}
                        variant="dark"
                        size='sm'
                        disabled={disableBtnCreateServiceProposal}
                        onClick={actualizarCotizacion}>
                    <span className='fw-bold fs-6'>
                    {msgBtnCreateServiceProposal}
                    </span>
                </Button>
            </Container>

            <ModalMessage show={showMessage} close={() => setShowMessage(false)} error={error} message={message}/>

            {
                catalogoServicio &&
                <CatalogoServicioModal show={showCatalogoServicioPanel}
                                       catalogoServicio={catalogoServicio}
                                       close={() => {
                                           setShowCatalogoServicioPanel(false)
                                       }}
                                       downloadDocCotizacionPrueba={(tempCatalogoServicio: CatalogoServicio) => {

                                           if (isFormValid() && tempCatalogoServicio) {
                                               alert("Ejecutado");

                                               const servicioPrueba = newInstanciaCotizacion();
                                               const documentos = cloneDeep(tempCatalogoServicio.documentos);
                                               const subservicios = cloneDeep(tempCatalogoServicio.subservicios);
                                               const sumaDescuentos = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                                   return accumulator + (item.precio * (item.porcentajeDescuento / 100));
                                               }, 0);
                                               const sumaPrecios = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                                   return accumulator + item.precio;
                                               }, 0);
                                               const iva = (sumaPrecios - sumaDescuentos) * 0.16;
                                               servicioPrueba.descripcion = tempCatalogoServicio.descripcion;
                                               servicioPrueba.precio = sumaPrecios;
                                               servicioPrueba.descuento = sumaDescuentos;
                                               servicioPrueba.iva = iva;
                                               servicioPrueba.subtotal = (sumaPrecios - sumaDescuentos);
                                               servicioPrueba.total = (sumaPrecios - sumaDescuentos) + iva;
                                               servicioPrueba.subservicios = subservicios;
                                               servicioPrueba.documentos = documentos;
                                               servicioPrueba.plantillaDocumentoCotizacion = tempCatalogoServicio.plantillaDescargable;
                                               registrarServicioPrueba(servicioPrueba).then(resp => {
                                                   if (resp.statusCode === OK) {
                                                       fetch(`api/catalogo/cotizacion/prueba/${resp.data.id}`)
                                                           .then(response => {
                                                               if (tempCatalogoServicio.plantillaDescargable) {
                                                                   response.blob().then(blob => {
                                                                       let url = window.URL.createObjectURL(blob);
                                                                       let a = document.createElement('a');
                                                                       a.href = url;
                                                                       a.download = `${tempCatalogoServicio.plantillaDescargable?.nombre}`;
                                                                       a.click();
                                                                   });
                                                               }
                                                           });
                                                   } else {
                                                       mostrarMensajeError(resp.message)
                                                   }
                                               }).catch(
                                                   err => mostrarMensajeError("Lo sentimos ocurrió un error al intentar descargar el documento de prueba")
                                               );
                                           }
                                       }}
                                       cambiarPlantilla={uploadTmpFile}
                                       guardarCambios={(e: CatalogoServicio) => {
                                           const subservicios = cloneDeep(e.subservicios);
                                           const sumaDescuentos = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                               return accumulator + (item.precio * (item.porcentajeDescuento / 100));
                                           }, 0);
                                           const sumaPrecios = subservicios.reduce((accumulator: number, item: Subservicio) => {
                                               return accumulator + item.precio;
                                           }, 0);
                                           const iva = (sumaPrecios - sumaDescuentos) * 0.16;

                                           const newCatalogoServicio = cloneDeep(e);
                                           newCatalogoServicio.iva = iva;
                                           newCatalogoServicio.subtotal = sumaPrecios;
                                           newCatalogoServicio.descuento = sumaDescuentos;
                                           setCatalogoServicio(newCatalogoServicio);
                                           setShowCatalogoServicioPanel(false);
                                       }}/>
            }
        </Container>
    )
}